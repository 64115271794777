export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: GraphQL_Date;
  /** Notification emails scalar */
  NotificationEmailsScalar: GraphQL_NotificationEmailsScalar;
};

export enum AccessTokenError {
  EmailDoesNotExist = 'EmailDoesNotExist',
  EmailNotSent = 'EmailNotSent',
  EmailOrPasswordNotSent = 'EmailOrPasswordNotSent',
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  ErrorOnOrganizationCreation = 'ErrorOnOrganizationCreation',
  GoogleTokenInvalid = 'GoogleTokenInvalid',
  InvalidCharityNumber = 'InvalidCharityNumber',
  InvalidOrganizationName = 'InvalidOrganizationName',
  NoDonorAllowedOnMobileApp = 'NoDonorAllowedOnMobileApp',
  PasswordDoNotMatch = 'PasswordDoNotMatch',
  ResetTokenDoNotMatch = 'ResetTokenDoNotMatch',
  StytchOrganizationIdNotFound = 'StytchOrganizationIdNotFound',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  TwoFactorCodeDoesNotMatch = 'TwoFactorCodeDoesNotMatch',
  TwoFactorCodeExpired = 'TwoFactorCodeExpired',
  UnauthorizedToken = 'UnauthorizedToken',
  UrlInvalid = 'UrlInvalid',
  UserAlreadyExistsWithOrganization = 'UserAlreadyExistsWithOrganization',
  UserNotFoundWithOrganization = 'UserNotFoundWithOrganization'
}

export type AccessTokenObject = {
  __typename?: 'AccessTokenObject';
  accessToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<AccessTokenError>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
  shouldAuthenticateBySms?: Maybe<Scalars['Boolean']>;
  shouldAuthenticateWithTwoFactor?: Maybe<Scalars['Boolean']>;
  shouldRedirectToMFAConfig?: Maybe<Scalars['Boolean']>;
  stytchVerdict?: Maybe<Scalars['String']>;
};

export type AccessTokenObject5csoj1Lr = {
  __typename?: 'AccessTokenObject5csoj1LR';
  error?: Maybe<AccessTokenObject5csoj1LrErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject5csoj1LrErrorObject = {
  __typename?: 'AccessTokenObject5csoj1LRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObject942fKilK = {
  __typename?: 'AccessTokenObject942fKilK';
  error?: Maybe<AccessTokenObject942fKilKErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject942fKilKErrorObject = {
  __typename?: 'AccessTokenObject942fKilKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectKZot5p63 = {
  __typename?: 'AccessTokenObjectKZot5p63';
  error?: Maybe<AccessTokenObjectKZot5p63ErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectKZot5p63ErrorObject = {
  __typename?: 'AccessTokenObjectKZot5p63ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectO7muNxRz = {
  __typename?: 'AccessTokenObjectO7muNXRz';
  error?: Maybe<AccessTokenObjectO7muNxRzErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectO7muNxRzErrorObject = {
  __typename?: 'AccessTokenObjectO7muNXRzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectR6PuXmaJ = {
  __typename?: 'AccessTokenObjectR6PUXmaJ';
  error?: Maybe<AccessTokenObjectR6PuXmaJErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectR6PuXmaJErrorObject = {
  __typename?: 'AccessTokenObjectR6PUXmaJErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectXoaZ2zhb = {
  __typename?: 'AccessTokenObjectXoaZ2zhb';
  error?: Maybe<AccessTokenObjectXoaZ2zhbErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectXoaZ2zhbErrorObject = {
  __typename?: 'AccessTokenObjectXoaZ2zhbErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectbfkleYbd = {
  __typename?: 'AccessTokenObjectbfkleYbd';
  error?: Maybe<AccessTokenObjectbfkleYbdErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectbfkleYbdErrorObject = {
  __typename?: 'AccessTokenObjectbfkleYbdErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenResponseObject = {
  __typename?: 'AccessTokenResponseObject';
  error?: Maybe<AccessTokenResponseObjectErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenResponseObjectErrorObject = {
  __typename?: 'AccessTokenResponseObjectErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccountBalancesObject = {
  __typename?: 'AccountBalancesObject';
  available?: Maybe<Array<BalanceObject>>;
  issuing?: Maybe<AccountBalancesObject>;
  pending?: Maybe<Array<BalanceObject>>;
};

export type AccountBalancesObjectUfVfnaNs = {
  __typename?: 'AccountBalancesObjectUfVfnaNS';
  error?: Maybe<AccountBalancesObjectUfVfnaNsErrorObject>;
  object?: Maybe<AccountBalancesObject>;
};

export type AccountBalancesObjectUfVfnaNsErrorObject = {
  __typename?: 'AccountBalancesObjectUfVfnaNSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ActivateDonationFormInput = {
  donationFormId: Scalars['String'];
  isOnboarding: Scalars['Boolean'];
};

export type ActivateTicketingInput = {
  isOnboarding: Scalars['Boolean'];
  ticketingId: Scalars['String'];
};

export type AggregateRateFieldsObject = {
  __typename?: 'AggregateRateFieldsObject';
  commandId: Scalars['String'];
  rateFields: Array<RateFieldObjectWithCount>;
};

export type AggregateRateFieldsObjectKsSdD7gZ = {
  __typename?: 'AggregateRateFieldsObjectKSSdD7gZ';
  error?: Maybe<AggregateRateFieldsObjectKsSdD7gZErrorObject>;
  items?: Maybe<Array<AggregateRateFieldsObject>>;
};

export type AggregateRateFieldsObjectKsSdD7gZErrorObject = {
  __typename?: 'AggregateRateFieldsObjectKSSdD7gZErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AmplitudeVariantValue {
  A = 'A',
  B = 'B',
  C = 'C',
  CONTROL = 'CONTROL',
  OFF = 'OFF'
}

export type AnswerInput = {
  answer?: InputMaybe<Scalars['String']>;
  choiceIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  questionId: Scalars['String'];
  rateId?: InputMaybe<Scalars['String']>;
  utm?: InputMaybe<Scalars['String']>;
};

export type AnswerObject = {
  __typename?: 'AnswerObject';
  answer?: Maybe<Scalars['String']>;
  choiceIds?: Maybe<Array<Scalars['String']>>;
  choices?: Maybe<Array<ChoiceObject>>;
  commandId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  productTicketId?: Maybe<Scalars['String']>;
  question?: Maybe<QuestionObject>;
  questionId: Scalars['String'];
  ticketId?: Maybe<Scalars['String']>;
};

export type AnswerObject54gZg9Bm = {
  __typename?: 'AnswerObject54gZG9Bm';
  error?: Maybe<AnswerObject54gZg9BmErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObject54gZg9BmErrorObject = {
  __typename?: 'AnswerObject54gZG9BmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectPTnoDhpW = {
  __typename?: 'AnswerObjectPTnoDhpW';
  error?: Maybe<AnswerObjectPTnoDhpWErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectPTnoDhpWErrorObject = {
  __typename?: 'AnswerObjectPTnoDhpWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectYxaWt5rq = {
  __typename?: 'AnswerObjectYXAWt5rq';
  error?: Maybe<AnswerObjectYxaWt5rqErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectYxaWt5rqErrorObject = {
  __typename?: 'AnswerObjectYXAWt5rqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseListObject = {
  __typename?: 'AnswerResponseListObject';
  error?: Maybe<AnswerResponseListObjectErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerResponseListObjectErrorObject = {
  __typename?: 'AnswerResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseObject = {
  __typename?: 'AnswerResponseObject';
  error?: Maybe<AnswerResponseObjectErrorObject>;
  object?: Maybe<AnswerObject>;
};

export type AnswerResponseObjectErrorObject = {
  __typename?: 'AnswerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ApplicationInitialStatusObject = {
  __typename?: 'ApplicationInitialStatusObject';
  firstForm?: Maybe<FirstFormObject>;
  hasActiveDonationForm: Scalars['Boolean'];
  hasActiveTicketing: Scalars['Boolean'];
  hasArchivedDonationForm: Scalars['Boolean'];
  hasArchivedTicketing: Scalars['Boolean'];
  hasDonationForm: Scalars['Boolean'];
  hasTicketing: Scalars['Boolean'];
  latestForm?: Maybe<FrontendFormObject>;
};

export type ApplicationInitialStatusResponseObject = {
  __typename?: 'ApplicationInitialStatusResponseObject';
  error?: Maybe<ApplicationInitialStatusResponseObjectErrorObject>;
  object?: Maybe<ApplicationInitialStatusObject>;
};

export type ApplicationInitialStatusResponseObjectErrorObject = {
  __typename?: 'ApplicationInitialStatusResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AvailablePaymentCurrency {
  Cad = 'Cad',
  Usd = 'Usd'
}

export type BalanceObject = {
  __typename?: 'BalanceObject';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type BalanceTransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export enum BalanceTransactionType {
  issuing_authorization_hold = 'issuing_authorization_hold',
  issuing_dispute = 'issuing_dispute',
  issuing_transaction = 'issuing_transaction',
  payment = 'payment',
  payment_refund = 'payment_refund',
  payout = 'payout',
  pending_topup = 'pending_topup',
  scheduled_payout = 'scheduled_payout',
  topup = 'topup'
}

export enum BankAccountStatus {
  Errored = 'Errored',
  Verified = 'Verified'
}

export type BidRateObject = {
  __typename?: 'BidRateObject';
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type BidRateObjectQtSiO7Rv = {
  __typename?: 'BidRateObjectQtSiO7Rv';
  error?: Maybe<BidRateObjectQtSiO7RvErrorObject>;
  items?: Maybe<Array<BidRateObject>>;
};

export type BidRateObjectQtSiO7RvErrorObject = {
  __typename?: 'BidRateObjectQtSiO7RvErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean0NLtHXr6 = {
  __typename?: 'Boolean0NLtHXr6';
  error?: Maybe<Boolean0NLtHXr6ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean0NLtHXr6ErrorObject = {
  __typename?: 'Boolean0NLtHXr6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean1vD1GgBi = {
  __typename?: 'Boolean1vD1GgBi';
  error?: Maybe<Boolean1vD1GgBiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean1vD1GgBiErrorObject = {
  __typename?: 'Boolean1vD1GgBiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean2NOkXnud = {
  __typename?: 'Boolean2NOkXnud';
  error?: Maybe<Boolean2NOkXnudErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean2NOkXnudErrorObject = {
  __typename?: 'Boolean2NOkXnudErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean2g5b0dx8 = {
  __typename?: 'Boolean2g5b0dx8';
  error?: Maybe<Boolean2g5b0dx8ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean2g5b0dx8ErrorObject = {
  __typename?: 'Boolean2g5b0dx8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean6V9877ec = {
  __typename?: 'Boolean6V9877ec';
  error?: Maybe<Boolean6V9877ecErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean6V9877ecErrorObject = {
  __typename?: 'Boolean6V9877ecErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean6fDMg4My = {
  __typename?: 'Boolean6fDMg4My';
  error?: Maybe<Boolean6fDMg4MyErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean6fDMg4MyErrorObject = {
  __typename?: 'Boolean6fDMg4MyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean7A0iTcGs = {
  __typename?: 'Boolean7A0iTcGS';
  error?: Maybe<Boolean7A0iTcGsErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean7A0iTcGsErrorObject = {
  __typename?: 'Boolean7A0iTcGSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean8H9gr810 = {
  __typename?: 'Boolean8H9gr810';
  error?: Maybe<Boolean8H9gr810ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean8H9gr810ErrorObject = {
  __typename?: 'Boolean8H9gr810ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean9No4gStq = {
  __typename?: 'Boolean9NO4gSTQ';
  error?: Maybe<Boolean9No4gStqErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean9No4gStqErrorObject = {
  __typename?: 'Boolean9NO4gSTQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean64hOmGlN = {
  __typename?: 'Boolean64hOmGlN';
  error?: Maybe<Boolean64hOmGlNErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean64hOmGlNErrorObject = {
  __typename?: 'Boolean64hOmGlNErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanAooArEq8 = {
  __typename?: 'BooleanAOOArEQ8';
  error?: Maybe<BooleanAooArEq8ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanAooArEq8ErrorObject = {
  __typename?: 'BooleanAOOArEQ8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanBh28wO1P = {
  __typename?: 'BooleanBH28wO1P';
  error?: Maybe<BooleanBh28wO1PErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanBh28wO1PErrorObject = {
  __typename?: 'BooleanBH28wO1PErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanBJvxqpDw = {
  __typename?: 'BooleanBJvxqpDw';
  error?: Maybe<BooleanBJvxqpDwErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanBJvxqpDwErrorObject = {
  __typename?: 'BooleanBJvxqpDwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanDDwUtZgj = {
  __typename?: 'BooleanDDwUtZGJ';
  error?: Maybe<BooleanDDwUtZgjErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanDDwUtZgjErrorObject = {
  __typename?: 'BooleanDDwUtZGJErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanFcdGvcU2 = {
  __typename?: 'BooleanFCDGvcU2';
  error?: Maybe<BooleanFcdGvcU2ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanFcdGvcU2ErrorObject = {
  __typename?: 'BooleanFCDGvcU2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanFibQ09Ub = {
  __typename?: 'BooleanFibQ09UB';
  error?: Maybe<BooleanFibQ09UbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanFibQ09UbErrorObject = {
  __typename?: 'BooleanFibQ09UBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanFt4vsewb = {
  __typename?: 'BooleanFt4vsewb';
  error?: Maybe<BooleanFt4vsewbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanFt4vsewbErrorObject = {
  __typename?: 'BooleanFt4vsewbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanH7j89lNk = {
  __typename?: 'BooleanH7j89lNK';
  error?: Maybe<BooleanH7j89lNkErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanH7j89lNkErrorObject = {
  __typename?: 'BooleanH7j89lNKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanHc5Bq7Xf = {
  __typename?: 'BooleanHC5BQ7Xf';
  error?: Maybe<BooleanHc5Bq7XfErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanHc5Bq7XfErrorObject = {
  __typename?: 'BooleanHC5BQ7XfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanHiy9J4Iv = {
  __typename?: 'BooleanHiy9J4Iv';
  error?: Maybe<BooleanHiy9J4IvErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanHiy9J4IvErrorObject = {
  __typename?: 'BooleanHiy9J4IvErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanITfQt5og = {
  __typename?: 'BooleanITfQt5og';
  error?: Maybe<BooleanITfQt5ogErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanITfQt5ogErrorObject = {
  __typename?: 'BooleanITfQt5ogErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanIsE3y9S0 = {
  __typename?: 'BooleanIsE3y9S0';
  error?: Maybe<BooleanIsE3y9S0ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanIsE3y9S0ErrorObject = {
  __typename?: 'BooleanIsE3y9S0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanJz1f21qE = {
  __typename?: 'BooleanJZ1f21qE';
  error?: Maybe<BooleanJz1f21qEErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanJz1f21qEErrorObject = {
  __typename?: 'BooleanJZ1f21qEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanJp0DvTx7 = {
  __typename?: 'BooleanJp0DvTX7';
  error?: Maybe<BooleanJp0DvTx7ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanJp0DvTx7ErrorObject = {
  __typename?: 'BooleanJp0DvTX7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanM3Fjcbdm = {
  __typename?: 'BooleanM3FJCBDM';
  error?: Maybe<BooleanM3FjcbdmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanM3FjcbdmErrorObject = {
  __typename?: 'BooleanM3FJCBDMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanM5tiwNkd = {
  __typename?: 'BooleanM5tiwNKD';
  error?: Maybe<BooleanM5tiwNkdErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanM5tiwNkdErrorObject = {
  __typename?: 'BooleanM5tiwNKDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanO9zZto7m = {
  __typename?: 'BooleanO9zZTO7m';
  error?: Maybe<BooleanO9zZto7mErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanO9zZto7mErrorObject = {
  __typename?: 'BooleanO9zZTO7mErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanOkkpzWoO = {
  __typename?: 'BooleanOkkpzWoO';
  error?: Maybe<BooleanOkkpzWoOErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanOkkpzWoOErrorObject = {
  __typename?: 'BooleanOkkpzWoOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanPCxdp4Tt = {
  __typename?: 'BooleanPCxdp4Tt';
  error?: Maybe<BooleanPCxdp4TtErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanPCxdp4TtErrorObject = {
  __typename?: 'BooleanPCxdp4TtErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanPpQik8eu = {
  __typename?: 'BooleanPpQIK8eu';
  error?: Maybe<BooleanPpQik8euErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanPpQik8euErrorObject = {
  __typename?: 'BooleanPpQIK8euErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanQkcq0Y1c = {
  __typename?: 'BooleanQKCQ0Y1c';
  error?: Maybe<BooleanQkcq0Y1cErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanQkcq0Y1cErrorObject = {
  __typename?: 'BooleanQKCQ0Y1cErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanResponseObject = {
  __typename?: 'BooleanResponseObject';
  error?: Maybe<BooleanResponseObjectErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanResponseObjectErrorObject = {
  __typename?: 'BooleanResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanTy8g0vpB = {
  __typename?: 'BooleanTY8g0vpB';
  error?: Maybe<BooleanTy8g0vpBErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanTy8g0vpBErrorObject = {
  __typename?: 'BooleanTY8g0vpBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanVic2JlOc = {
  __typename?: 'BooleanVic2JLOc';
  error?: Maybe<BooleanVic2JlOcErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanVic2JlOcErrorObject = {
  __typename?: 'BooleanVic2JLOcErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanXjFaaesd = {
  __typename?: 'BooleanXjFaaesd';
  error?: Maybe<BooleanXjFaaesdErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanXjFaaesdErrorObject = {
  __typename?: 'BooleanXjFaaesdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanbQvj90Hv = {
  __typename?: 'BooleanbQVJ90HV';
  error?: Maybe<BooleanbQvj90HvErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanbQvj90HvErrorObject = {
  __typename?: 'BooleanbQVJ90HVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanca6Yn8yI = {
  __typename?: 'Booleanca6YN8yI';
  error?: Maybe<Booleanca6Yn8yIErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanca6Yn8yIErrorObject = {
  __typename?: 'Booleanca6YN8yIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleancirl0Wvo = {
  __typename?: 'Booleancirl0WVO';
  error?: Maybe<Booleancirl0WvoErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleancirl0WvoErrorObject = {
  __typename?: 'Booleancirl0WVOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleand2kQ7m0b = {
  __typename?: 'Booleand2kQ7m0b';
  error?: Maybe<Booleand2kQ7m0bErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleand2kQ7m0bErrorObject = {
  __typename?: 'Booleand2kQ7m0bErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleand9mwI22L = {
  __typename?: 'Booleand9mwI22L';
  error?: Maybe<Booleand9mwI22LErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleand9mwI22LErrorObject = {
  __typename?: 'Booleand9mwI22LErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleandlvRAnkw = {
  __typename?: 'BooleandlvRAnkw';
  error?: Maybe<BooleandlvRAnkwErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleandlvRAnkwErrorObject = {
  __typename?: 'BooleandlvRAnkwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleaneRrDcavO = {
  __typename?: 'BooleaneRRDcavO';
  error?: Maybe<BooleaneRrDcavOErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleaneRrDcavOErrorObject = {
  __typename?: 'BooleaneRRDcavOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleangIpUfPgu = {
  __typename?: 'BooleangIpUfPGU';
  error?: Maybe<BooleangIpUfPguErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleangIpUfPguErrorObject = {
  __typename?: 'BooleangIpUfPGUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleangcYAwwDi = {
  __typename?: 'BooleangcYAwwDi';
  error?: Maybe<BooleangcYAwwDiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleangcYAwwDiErrorObject = {
  __typename?: 'BooleangcYAwwDiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanglh5Egor = {
  __typename?: 'Booleanglh5EGOR';
  error?: Maybe<Booleanglh5EgorErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanglh5EgorErrorObject = {
  __typename?: 'Booleanglh5EGORErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanjWmF2qxi = {
  __typename?: 'BooleanjWmF2qxi';
  error?: Maybe<BooleanjWmF2qxiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanjWmF2qxiErrorObject = {
  __typename?: 'BooleanjWmF2qxiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanjtadtopw = {
  __typename?: 'Booleanjtadtopw';
  error?: Maybe<BooleanjtadtopwErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanjtadtopwErrorObject = {
  __typename?: 'BooleanjtadtopwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleankVit2hBp = {
  __typename?: 'BooleankVit2hBP';
  error?: Maybe<BooleankVit2hBpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleankVit2hBpErrorObject = {
  __typename?: 'BooleankVit2hBPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanlzZpKuKk = {
  __typename?: 'BooleanlzZPKuKK';
  error?: Maybe<BooleanlzZpKuKkErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanlzZpKuKkErrorObject = {
  __typename?: 'BooleanlzZPKuKKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleannnzdTqAt = {
  __typename?: 'BooleannnzdTqAT';
  error?: Maybe<BooleannnzdTqAtErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleannnzdTqAtErrorObject = {
  __typename?: 'BooleannnzdTqATErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleannxnEpfWu = {
  __typename?: 'BooleannxnEpfWu';
  error?: Maybe<BooleannxnEpfWuErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleannxnEpfWuErrorObject = {
  __typename?: 'BooleannxnEpfWuErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanpWm7ZKb3 = {
  __typename?: 'BooleanpWM7ZKb3';
  error?: Maybe<BooleanpWm7ZKb3ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanpWm7ZKb3ErrorObject = {
  __typename?: 'BooleanpWM7ZKb3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanpvXgVy9L = {
  __typename?: 'BooleanpvXgVy9L';
  error?: Maybe<BooleanpvXgVy9LErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanpvXgVy9LErrorObject = {
  __typename?: 'BooleanpvXgVy9LErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanq9ldXDrB = {
  __typename?: 'Booleanq9ldXDrB';
  error?: Maybe<Booleanq9ldXDrBErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanq9ldXDrBErrorObject = {
  __typename?: 'Booleanq9ldXDrBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanrLmok7Kx = {
  __typename?: 'BooleanrLmok7KX';
  error?: Maybe<BooleanrLmok7KxErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanrLmok7KxErrorObject = {
  __typename?: 'BooleanrLmok7KXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanrmldcJaf = {
  __typename?: 'BooleanrmldcJaf';
  error?: Maybe<BooleanrmldcJafErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanrmldcJafErrorObject = {
  __typename?: 'BooleanrmldcJafErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleansBohXvUt = {
  __typename?: 'BooleansBOHXvUT';
  error?: Maybe<BooleansBohXvUtErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleansBohXvUtErrorObject = {
  __typename?: 'BooleansBOHXvUTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleansqtkkQcU = {
  __typename?: 'BooleansqtkkQcU';
  error?: Maybe<BooleansqtkkQcUErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleansqtkkQcUErrorObject = {
  __typename?: 'BooleansqtkkQcUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleantgdCrqQq = {
  __typename?: 'BooleantgdCrqQQ';
  error?: Maybe<BooleantgdCrqQqErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleantgdCrqQqErrorObject = {
  __typename?: 'BooleantgdCrqQQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanuaIyxoCt = {
  __typename?: 'BooleanuaIYXOCt';
  error?: Maybe<BooleanuaIyxoCtErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanuaIyxoCtErrorObject = {
  __typename?: 'BooleanuaIYXOCtErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanv9jhrDmm = {
  __typename?: 'Booleanv9jhrDMM';
  error?: Maybe<Booleanv9jhrDmmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanv9jhrDmmErrorObject = {
  __typename?: 'Booleanv9jhrDMMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanwOrSjKFx = {
  __typename?: 'BooleanwORSjKFx';
  error?: Maybe<BooleanwOrSjKFxErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanwOrSjKFxErrorObject = {
  __typename?: 'BooleanwORSjKFxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxrxL14f1 = {
  __typename?: 'BooleanxrxL14f1';
  error?: Maybe<BooleanxrxL14f1ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxrxL14f1ErrorObject = {
  __typename?: 'BooleanxrxL14f1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleany7Bh7sTe = {
  __typename?: 'Booleany7BH7sTE';
  error?: Maybe<Booleany7Bh7sTeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleany7Bh7sTeErrorObject = {
  __typename?: 'Booleany7BH7sTEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanyWMogBbI = {
  __typename?: 'BooleanyWMogBbI';
  error?: Maybe<BooleanyWMogBbIErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanyWMogBbIErrorObject = {
  __typename?: 'BooleanyWMogBbIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanyylcEate = {
  __typename?: 'BooleanyylcEate';
  error?: Maybe<BooleanyylcEateErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanyylcEateErrorObject = {
  __typename?: 'BooleanyylcEateErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CardHolderStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Inactive = 'Inactive'
}

export type CardVolumeObject = {
  __typename?: 'CardVolumeObject';
  amount: Scalars['Float'];
  count: Scalars['Float'];
};

export type CardVolumeResponseObject = {
  __typename?: 'CardVolumeResponseObject';
  error?: Maybe<CardVolumeResponseObjectErrorObject>;
  object?: Maybe<CardVolumeObject>;
};

export type CardVolumeResponseObjectErrorObject = {
  __typename?: 'CardVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CheckIfResetPasswordIsValidInput = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type CheckoutSessionObject = {
  __typename?: 'CheckoutSessionObject';
  cancelUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
};

export type CheckoutSessionResponseObject = {
  __typename?: 'CheckoutSessionResponseObject';
  error?: Maybe<CheckoutSessionResponseObjectErrorObject>;
  object?: Maybe<CheckoutSessionObject>;
};

export type CheckoutSessionResponseObjectErrorObject = {
  __typename?: 'CheckoutSessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ChoiceFieldInput = {
  body: Scalars['String'];
  choiceId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceFieldObject = {
  __typename?: 'ChoiceFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceInput = {
  choiceFields: Array<ChoiceFieldInput>;
  id: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  maximum?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChoiceObject = {
  __typename?: 'ChoiceObject';
  choiceFields: Array<ChoiceFieldObject>;
  countAlreadyAnswered?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  maximum?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChunkImportObject = {
  __typename?: 'ChunkImportObject';
  chunkIndex: Scalars['Float'];
  zeffyImport: ZeffyImportObject;
};

export type ChunkImportObjectpD0Zyuux = {
  __typename?: 'ChunkImportObjectpD0Zyuux';
  error?: Maybe<ChunkImportObjectpD0ZyuuxErrorObject>;
  object?: Maybe<ChunkImportObject>;
};

export type ChunkImportObjectpD0ZyuuxErrorObject = {
  __typename?: 'ChunkImportObjectpD0ZyuuxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ClusterObject = {
  __typename?: 'ClusterObject';
  coordinates: CoordinatesObject;
  count: Scalars['Float'];
  cursorType: CursorType;
  displayedLatitude: Scalars['Float'];
  displayedLongitude: Scalars['Float'];
  id: Scalars['String'];
};

export type ClusterResponseObject = {
  __typename?: 'ClusterResponseObject';
  error?: Maybe<ClusterResponseObjectErrorObject>;
  items?: Maybe<Array<ClusterObject>>;
};

export type ClusterResponseObjectErrorObject = {
  __typename?: 'ClusterResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandLang {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type CommandObject = {
  __typename?: 'CommandObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAtUtc: Scalars['Date'];
  cs?: Maybe<Scalars['String']>;
  currency: AvailablePaymentCurrency;
  discount?: Maybe<DiscountObject>;
  discountAmount: Scalars['Int'];
  discountId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  eligibleAmount: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formLang: CommandLang;
  formType: FormType;
  id: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  locale: Locales;
  organization: OrganizationObject;
  organizationCountry: Scalars['String'];
  organizationId: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  postalCode?: Maybe<Scalars['String']>;
  productBids?: Maybe<Array<ProductBidObject>>;
  productDonation?: Maybe<ProductDonationObject>;
  productTickets?: Maybe<Array<ProductTicketObject>>;
  productsAmount: Scalars['Int'];
  region?: Maybe<Scalars['String']>;
  source: CommandSource;
  status: CommandStatus;
  tipAmount: Scalars['Int'];
  tipPercentage: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type CommandObject5IXkLnyr = {
  __typename?: 'CommandObject5IXkLNYR';
  error?: Maybe<CommandObject5IXkLnyrErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObject5IXkLnyrErrorObject = {
  __typename?: 'CommandObject5IXkLNYRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObject8kjDqt0y = {
  __typename?: 'CommandObject8kjDqt0y';
  error?: Maybe<CommandObject8kjDqt0yErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObject8kjDqt0yErrorObject = {
  __typename?: 'CommandObject8kjDqt0yErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectGmQkhkzP = {
  __typename?: 'CommandObjectGMQkhkzP';
  error?: Maybe<CommandObjectGmQkhkzPErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectGmQkhkzPErrorObject = {
  __typename?: 'CommandObjectGMQkhkzPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectg1z1rFnF = {
  __typename?: 'CommandObjectg1z1rFnF';
  error?: Maybe<CommandObjectg1z1rFnFErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectg1z1rFnFErrorObject = {
  __typename?: 'CommandObjectg1z1rFnFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectszfqffkS = {
  __typename?: 'CommandObjectszfqffkS';
  error?: Maybe<CommandObjectszfqffkSErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectszfqffkSErrorObject = {
  __typename?: 'CommandObjectszfqffkSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectvCcecFe2 = {
  __typename?: 'CommandObjectvCCECFe2';
  error?: Maybe<CommandObjectvCcecFe2ErrorObject>;
  items?: Maybe<Array<CommandObject>>;
};

export type CommandObjectvCcecFe2ErrorObject = {
  __typename?: 'CommandObjectvCCECFe2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseListObjectErrorObject = {
  __typename?: 'CommandResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseObject = {
  __typename?: 'CommandResponseObject';
  error?: Maybe<CommandResponseObjectErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandResponseObjectErrorObject = {
  __typename?: 'CommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandSource {
  FormSubmission = 'FormSubmission',
  Import = 'Import',
  ManualEntry = 'ManualEntry'
}

export enum CommandStatus {
  Archived = 'Archived',
  Complete = 'Complete',
  Expired = 'Expired',
  Open = 'Open',
  Submitted = 'Submitted'
}

export type CommandSubscriptionObject = {
  __typename?: 'CommandSubscriptionObject';
  id: Scalars['String'];
  recurrenceInterval: SubscriptionRecurrenceInterval;
  status?: Maybe<NewSubscriptionStatus>;
  stripeSubscriptionId: Scalars['String'];
};

export type CommandSubscriptionResponseObject = {
  __typename?: 'CommandSubscriptionResponseObject';
  error?: Maybe<CommandSubscriptionResponseObjectErrorObject>;
  object?: Maybe<CommandSubscriptionObject>;
};

export type CommandSubscriptionResponseObjectErrorObject = {
  __typename?: 'CommandSubscriptionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CompleteStripeAuthError {
  ImpossibleToCreateStripeAccount = 'ImpossibleToCreateStripeAccount'
}

export type CompleteStripeAuthResponseObjectErrorObject = {
  __typename?: 'CompleteStripeAuthResponseObjectErrorObject';
  code?: Maybe<CompleteStripeAuthError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ConfirmPaymentIntentInput = {
  paymentIntentId: Scalars['String'];
};

export type ConfirmPaymentIntentObject = {
  __typename?: 'ConfirmPaymentIntentObject';
  nextAction?: Maybe<NextActionObject>;
};

export type ConfirmPaymentIntentObjectResponseObjectErrorObject = {
  __typename?: 'ConfirmPaymentIntentObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactIdWithTagsObject = {
  __typename?: 'ContactIdWithTagsObject';
  id: Scalars['String'];
  tags: Array<TagObject>;
};

export type ContactIdWithTagsObjectejpgTUkw = {
  __typename?: 'ContactIdWithTagsObjectejpgTUkw';
  error?: Maybe<ContactIdWithTagsObjectejpgTUkwErrorObject>;
  items?: Maybe<Array<ContactIdWithTagsObject>>;
};

export type ContactIdWithTagsObjectejpgTUkwErrorObject = {
  __typename?: 'ContactIdWithTagsObjectejpgTUkwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactKeyInfoObject = {
  __typename?: 'ContactKeyInfoObject';
  contactSince?: Maybe<Scalars['Date']>;
  largestDonation?: Maybe<Scalars['Float']>;
  monthlyRecurringContribution?: Maybe<Scalars['Float']>;
  totalContribution?: Maybe<Scalars['Float']>;
  yearlyRecurringContribution?: Maybe<Scalars['Float']>;
};

export type ContactKeyInfoObjectEWmiN8rr = {
  __typename?: 'ContactKeyInfoObjectEWmiN8rr';
  error?: Maybe<ContactKeyInfoObjectEWmiN8rrErrorObject>;
  object?: Maybe<ContactKeyInfoObject>;
};

export type ContactKeyInfoObjectEWmiN8rrErrorObject = {
  __typename?: 'ContactKeyInfoObjectEWmiN8rrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObject = {
  __typename?: 'ContactObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  donorType?: Maybe<DonorType>;
  firstName?: Maybe<Scalars['String']>;
  hasUnsubscribed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  organizationId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  unsubscribedEmailDeliveryId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  userId: Scalars['String'];
};

export type ContactObjectSkL0EWhW = {
  __typename?: 'ContactObjectSkL0EWhW';
  error?: Maybe<ContactObjectSkL0EWhWErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObjectSkL0EWhWErrorObject = {
  __typename?: 'ContactObjectSkL0EWhWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectaDhLvWqd = {
  __typename?: 'ContactObjectaDHLvWqd';
  error?: Maybe<ContactObjectaDhLvWqdErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectaDhLvWqdErrorObject = {
  __typename?: 'ContactObjectaDHLvWqdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectnyOtYk6t = {
  __typename?: 'ContactObjectnyOTYk6t';
  error?: Maybe<ContactObjectnyOtYk6tErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectnyOtYk6tErrorObject = {
  __typename?: 'ContactObjectnyOTYk6tErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectpfWtIdjw = {
  __typename?: 'ContactObjectpfWtIdjw';
  error?: Maybe<ContactObjectpfWtIdjwErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObjectpfWtIdjwErrorObject = {
  __typename?: 'ContactObjectpfWtIdjwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactResponseObject = {
  __typename?: 'ContactResponseObject';
  error?: Maybe<ContactResponseObjectErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactResponseObjectErrorObject = {
  __typename?: 'ContactResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CoordinatesObject = {
  __typename?: 'CoordinatesObject';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateCardHolderInput = {
  address: Scalars['String'];
  agreedToStripeCardIssuingTermsAt: Scalars['Date'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  dateOfBirth: DateOfBirthInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};

export type CreateDonationAmountInput = {
  amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isRecurrent?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval?: InputMaybe<ProductDonationRecurrenceInterval>;
  sortIndex?: InputMaybe<Scalars['Float']>;
};

export type CreateDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts: Array<CreateDonationAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  postDonationUrl?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateDraftDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  displayAddressQuestion?: InputMaybe<Scalars['Boolean']>;
  donationFormFields: Array<CreateDonationFormFieldInput>;
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isOnboardingForm?: Scalars['Boolean'];
  isPrimaryCampaign?: InputMaybe<Scalars['Boolean']>;
  isPrimaryTeam?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<CreateLinkedTicketingInput>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  organizationId?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  registrationCommandId?: InputMaybe<Scalars['String']>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  registrationOrderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FormStatus>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['Float']>;
  teamId?: InputMaybe<Scalars['String']>;
  ticketingId?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type CreateFrontendOrderResponseObjectErrorObject = {
  __typename?: 'CreateFrontendOrderResponseObjectErrorObject';
  code?: Maybe<CreateOrderResponseError>;
  data?: Maybe<CreateOrderErrorDataObject>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateIssuingTopupInput = {
  amount: Scalars['Int'];
  plaidAccountId?: InputMaybe<Scalars['String']>;
  plaidPublicToken?: InputMaybe<Scalars['String']>;
};

export type CreateLinkedTicketingInput = {
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  rates: Array<CreateRateInput>;
};

export type CreateMobileCommandInput = {
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  extraDonation: Scalars['Float'];
  formId: Scalars['String'];
  locale: Locales;
  paymentIntentId: Scalars['String'];
  productDonation?: InputMaybe<CreateMobileProductDonationInput>;
  productTickets?: InputMaybe<Array<CreateMobileTicketInput>>;
  productsAmount: Scalars['Int'];
  tipAmount: Scalars['Float'];
  totalAmount: Scalars['Float'];
};

export type CreateMobileCommandObject = {
  __typename?: 'CreateMobileCommandObject';
  id: Scalars['String'];
};

export type CreateMobileCommandResponseObject = {
  __typename?: 'CreateMobileCommandResponseObject';
  error?: Maybe<CreateMobileCommandResponseObjectErrorObject>;
  object?: Maybe<CreateMobileCommandObject>;
};

export type CreateMobileCommandResponseObjectErrorObject = {
  __typename?: 'CreateMobileCommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateMobileProductDonationInput = {
  amount?: InputMaybe<Scalars['Int']>;
  inHonourEmailAddress?: InputMaybe<Scalars['String']>;
  inHonourEmailBody?: InputMaybe<Scalars['String']>;
  inHonourName?: InputMaybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: InputMaybe<Scalars['String']>;
};

export type CreateMobileTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal: Scalars['Boolean'];
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
};

export type CreateNewOrganizationInput = {
  country: OrganizationCountry;
  organizationName: Scalars['String'];
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOther: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type CreateOrderErrorDataObject = {
  __typename?: 'CreateOrderErrorDataObject';
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateOrderInput = {
  address?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers: Array<AnswerInput>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  email?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Float'];
  firstName?: InputMaybe<Scalars['String']>;
  isTapToPay?: InputMaybe<Scalars['Boolean']>;
  isTipMessageStayFree?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  occurrenceId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  paymentIntentId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  tip: Scalars['Float'];
  userId?: InputMaybe<Scalars['String']>;
};

export enum CreateOrderResponseError {
  ChoiceIsFull = 'ChoiceIsFull',
  ExpiredEarlyBirdRate = 'ExpiredEarlyBirdRate',
  NoTicketNorExtraDonation = 'NoTicketNorExtraDonation',
  NoTicketSelected = 'NoTicketSelected',
  OverpassedTicketingSeats = 'OverpassedTicketingSeats',
  RateIsFull = 'RateIsFull',
  ShouldSpecifyATicketingOccurrence = 'ShouldSpecifyATicketingOccurrence',
  TicketingIsClosed = 'TicketingIsClosed',
  TicketingIsFull = 'TicketingIsFull',
  TicketingOccurrenceNotFound = 'TicketingOccurrenceNotFound',
  UnknownError = 'UnknownError'
}

export type CreateOrderResponseObject = {
  __typename?: 'CreateOrderResponseObject';
  error?: Maybe<CreateOrderResponseError>;
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  order?: Maybe<OrderObject>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  locale: Locales;
  title: Scalars['String'];
};

export type CreateRateInput = {
  amount: Scalars['Float'];
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photoUrls?: InputMaybe<Array<Scalars['String']>>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields?: InputMaybe<Array<CreateRateFieldInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type CreateReceiptInput = {
  address?: InputMaybe<Scalars['String']>;
  advantageAmount?: InputMaybe<Scalars['Float']>;
  advantageDescription?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  annotation?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  donationDate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isImported?: InputMaybe<Scalars['Boolean']>;
  isSample?: InputMaybe<Scalars['Boolean']>;
  issuingAtUtc?: InputMaybe<Scalars['Date']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  postalCode?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReceiptStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTagInput = {
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal?: InputMaybe<Scalars['Boolean']>;
  rateId: Scalars['String'];
};

export type CreateTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  postEventBody?: InputMaybe<Scalars['String']>;
  postEventSubject?: InputMaybe<Scalars['String']>;
  postTransactionUrl?: InputMaybe<Scalars['String']>;
  reminderBody?: InputMaybe<Scalars['String']>;
  reminderSubject?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  duplicatedFromId?: InputMaybe<Scalars['String']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isOnboardingForm?: InputMaybe<Scalars['Boolean']>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale: Locales;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder: Array<QuestionInput>;
  questionsPerParticipant: Array<QuestionInput>;
  rates: Array<CreateRateInput>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  reminderSendDateOffset?: InputMaybe<Scalars['Float']>;
  seats?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<FormStatus>;
  ticketingFields: Array<CreateTicketingFieldInput>;
};

export type CreatedCampaignsObject = {
  __typename?: 'CreatedCampaignsObject';
  individualCampaigns?: Maybe<Array<FundraiserDonationFormObject>>;
  team?: Maybe<FundraiserDonationFormObject>;
};

export type CreatedIssuingPayoutInput = {
  amount: Scalars['Float'];
};

export type CreatedIssuingPayoutObject = {
  __typename?: 'CreatedIssuingPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type CreatedIssuingPayoutObjectAzZpVyDk = {
  __typename?: 'CreatedIssuingPayoutObjectAzZpVyDk';
  error?: Maybe<CreatedIssuingPayoutObjectAzZpVyDkErrorObject>;
  object?: Maybe<CreatedIssuingPayoutObject>;
};

export type CreatedIssuingPayoutObjectAzZpVyDkErrorObject = {
  __typename?: 'CreatedIssuingPayoutObjectAzZpVyDkErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CronQueues {
  AuctionsPaymentProcessor = 'AuctionsPaymentProcessor',
  CloseAuctions = 'CloseAuctions',
  GenerateBankStatement = 'GenerateBankStatement',
  GenerateSitemap = 'GenerateSitemap',
  HubspotNurturingDealCreation = 'HubspotNurturingDealCreation',
  InvitationReminder = 'InvitationReminder',
  MarkStaleTransactionsAsFailed = 'MarkStaleTransactionsAsFailed',
  MembershipReminders = 'MembershipReminders',
  NotifyActiveOrganizationsWithoutBankInfo = 'NotifyActiveOrganizationsWithoutBankInfo',
  ProcessBalanceTransfersToIssuing = 'ProcessBalanceTransfersToIssuing',
  ProcessIssuingRewards = 'ProcessIssuingRewards',
  ProcessReferralTransfers = 'ProcessReferralTransfers',
  ProcessScheduledPayouts = 'ProcessScheduledPayouts',
  Reminders = 'Reminders',
  RetryFailedTransfer = 'RetryFailedTransfer',
  SendDailyVisitorReport = 'SendDailyVisitorReport',
  SendEventManagementEmails = 'SendEventManagementEmails',
  SendMonthlyBalanceTransactionsExport = 'SendMonthlyBalanceTransactionsExport',
  SendMonthlyChurnedReport = 'SendMonthlyChurnedReport',
  SendPostEventEmailDeliveries = 'SendPostEventEmailDeliveries',
  SendScheduledEmailDeliveries = 'SendScheduledEmailDeliveries',
  SendSuggestDesktopCompletionEmail = 'SendSuggestDesktopCompletionEmail',
  SendWeeklyChurnedReport = 'SendWeeklyChurnedReport',
  TriggerAwaitingWithdrawalRefunds = 'TriggerAwaitingWithdrawalRefunds',
  UpdateFraudulentTransaction = 'UpdateFraudulentTransaction',
  UpdateMissingIrsInfo = 'UpdateMissingIrsInfo'
}

export type CurrentlyActiveOrganizationObject = {
  __typename?: 'CurrentlyActiveOrganizationObject';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CurrentlyActiveOrganizationResponseListObject = {
  __typename?: 'CurrentlyActiveOrganizationResponseListObject';
  error?: Maybe<OrganizationResponseListObjectErrorObject>;
  items?: Maybe<Array<CurrentlyActiveOrganizationObject>>;
};

export enum CursorType {
  Organization = 'Organization',
  Ticketing = 'Ticketing'
}

export type DashboardCommandFormListResponse = {
  __typename?: 'DashboardCommandFormListResponse';
  error?: Maybe<DashboardCommandFormListResponseErrorObject>;
  items?: Maybe<Array<FormObject>>;
};

export type DashboardCommandFormListResponseErrorObject = {
  __typename?: 'DashboardCommandFormListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRateObject = {
  __typename?: 'DashboardCommandRateObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type DashboardCommandRatesListResponseObject = {
  __typename?: 'DashboardCommandRatesListResponseObject';
  error?: Maybe<DashboardCommandRatesListResponseObjectErrorObject>;
  items?: Maybe<Array<DashboardCommandRatesObject>>;
};

export type DashboardCommandRatesListResponseObjectErrorObject = {
  __typename?: 'DashboardCommandRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRatesObject = {
  __typename?: 'DashboardCommandRatesObject';
  commandId: Scalars['String'];
  rates: Array<DashboardCommandRateObject>;
};

export type DashboardCountObject = {
  __typename?: 'DashboardCountObject';
  count: Scalars['Float'];
};

export type DashboardCountObjectmh17peyA = {
  __typename?: 'DashboardCountObjectmh17peyA';
  error?: Maybe<DashboardCountObjectmh17peyAErrorObject>;
  object?: Maybe<DashboardCountObject>;
};

export type DashboardCountObjectmh17peyAErrorObject = {
  __typename?: 'DashboardCountObjectmh17peyAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCountResponseObjectErrorObject = {
  __typename?: 'DashboardCountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DashboardPaymentStatus {
  DisputeLost = 'DisputeLost',
  Disputed = 'Disputed',
  Failed = 'Failed',
  Incomplete = 'Incomplete',
  Monthly = 'Monthly',
  OnHold = 'OnHold',
  PastDue = 'PastDue',
  Processing = 'Processing',
  Stopped = 'Stopped',
  Succeeded = 'Succeeded',
  Unprocessed = 'Unprocessed',
  Yearly = 'Yearly'
}

export type DashboardTransactionListResponseObjectErrorObject = {
  __typename?: 'DashboardTransactionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObject = {
  __typename?: 'DashboardTransactionObject';
  commandId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  displayStatus: DashboardPaymentStatus;
  email?: Maybe<Scalars['String']>;
  eventEndAt?: Maybe<Scalars['Date']>;
  eventStartAt?: Maybe<Scalars['Date']>;
  extraDonation?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formType: FormType;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isNewRecurringDonation?: Maybe<Scalars['Boolean']>;
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nextRenewalAt?: Maybe<Scalars['Date']>;
  paymentMethod?: Maybe<PaymentMethod>;
  payoutDate?: Maybe<Scalars['Date']>;
  receiptId?: Maybe<Scalars['String']>;
  recurrenceInterval?: Maybe<SubscriptionRecurrenceInterval>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount: Scalars['Int'];
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status: PaymentStatus;
  stoppedAt?: Maybe<Scalars['Date']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<NewSubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

export type DashboardTransactionObjectH9sUUdiV = {
  __typename?: 'DashboardTransactionObjectH9sUUdiV';
  error?: Maybe<DashboardTransactionObjectH9sUUdiVErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjectH9sUUdiVErrorObject = {
  __typename?: 'DashboardTransactionObjectH9sUUdiVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObjectY8qy6Mqd = {
  __typename?: 'DashboardTransactionObjectY8qy6MQD';
  error?: Maybe<DashboardTransactionObjectY8qy6MqdErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjectY8qy6MqdErrorObject = {
  __typename?: 'DashboardTransactionObjectY8qy6MQDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DateOfBirthInput = {
  day: Scalars['Float'];
  month: Scalars['Float'];
  year: Scalars['Float'];
};

export type DeactivateAccountInput = {
  reason: Scalars['String'];
};

export type DeactiveTwoFactorAuthInput = {
  password: Scalars['String'];
};

export type DeleteUserAccountInput = {
  reason: Scalars['String'];
};

export type DiscountObject = {
  __typename?: 'DiscountObject';
  amount: Scalars['Float'];
  body: Scalars['String'];
  id: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  remainingUses?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  ticketingId: Scalars['String'];
  type: DiscountType;
};

export type DiscountResponseObject = {
  __typename?: 'DiscountResponseObject';
  error?: Maybe<DiscountResponseObjectErrorObject>;
  object?: Maybe<DiscountObject>;
};

export type DiscountResponseObjectErrorObject = {
  __typename?: 'DiscountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DiscountType {
  Dollars = 'Dollars',
  Percentage = 'Percentage'
}

export type DonationFormAmountInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormAmountObject = {
  __typename?: 'DonationFormAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormCampaignFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  search: Scalars['String'];
};

export enum DonationFormCategory {
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  PeerToPeerCampaign = 'PeerToPeerCampaign'
}

export type DonationFormFieldFromCampaignObject = {
  __typename?: 'DonationFormFieldFromCampaignObject';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type DonationFormFieldObject = {
  __typename?: 'DonationFormFieldObject';
  chequeDescription?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  description: Scalars['String'];
  donationFormAmounts?: Maybe<Array<DonationFormAmountObject>>;
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<Scalars['String']>;
  emailObject?: Maybe<Scalars['String']>;
  fundraiserEmailBody?: Maybe<Scalars['String']>;
  fundraisingRegistrationWording?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  postDonationUrl?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  ticketingRegistrationWording?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DonationFormFromCampaignObject = {
  __typename?: 'DonationFormFromCampaignObject';
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  donationFormFields?: Maybe<Array<DonationFormFieldFromCampaignObject>>;
  id: Scalars['String'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  teamId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject = {
  __typename?: 'DonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject2qRuNxdg = {
  __typename?: 'DonationFormObject2qRuNxdg';
  error?: Maybe<DonationFormObject2qRuNxdgErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject2qRuNxdgErrorObject = {
  __typename?: 'DonationFormObject2qRuNxdgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject5lxUqFwg = {
  __typename?: 'DonationFormObject5lxUqFWG';
  error?: Maybe<DonationFormObject5lxUqFwgErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject5lxUqFwgErrorObject = {
  __typename?: 'DonationFormObject5lxUqFWGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject9Evp7ehe = {
  __typename?: 'DonationFormObject9EVP7ehe';
  error?: Maybe<DonationFormObject9Evp7eheErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject9Evp7eheErrorObject = {
  __typename?: 'DonationFormObject9EVP7eheErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject28lTnNyY = {
  __typename?: 'DonationFormObject28lTnNyY';
  error?: Maybe<DonationFormObject28lTnNyYErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObject28lTnNyYErrorObject = {
  __typename?: 'DonationFormObject28lTnNyYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject90bxPxuM = {
  __typename?: 'DonationFormObject90bxPxuM';
  error?: Maybe<DonationFormObject90bxPxuMErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject90bxPxuMErrorObject = {
  __typename?: 'DonationFormObject90bxPxuMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectAtgNqflE = {
  __typename?: 'DonationFormObjectAtgNqflE';
  error?: Maybe<DonationFormObjectAtgNqflEErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectAtgNqflEErrorObject = {
  __typename?: 'DonationFormObjectAtgNqflEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectEc4d53Ms = {
  __typename?: 'DonationFormObjectEC4d53MS';
  error?: Maybe<DonationFormObjectEc4d53MsErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectEc4d53MsErrorObject = {
  __typename?: 'DonationFormObjectEC4d53MSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectFxlVJfuG = {
  __typename?: 'DonationFormObjectFxlVJfuG';
  error?: Maybe<DonationFormObjectFxlVJfuGErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectFxlVJfuGErrorObject = {
  __typename?: 'DonationFormObjectFxlVJfuGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectL9imsWeq = {
  __typename?: 'DonationFormObjectL9imsWEQ';
  error?: Maybe<DonationFormObjectL9imsWeqErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectL9imsWeqErrorObject = {
  __typename?: 'DonationFormObjectL9imsWEQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectNuMsbHct = {
  __typename?: 'DonationFormObjectNuMSBHct';
  error?: Maybe<DonationFormObjectNuMsbHctErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectNuMsbHctErrorObject = {
  __typename?: 'DonationFormObjectNuMSBHctErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectSHrhhIww = {
  __typename?: 'DonationFormObjectSHrhhIWW';
  error?: Maybe<DonationFormObjectSHrhhIwwErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectSHrhhIwwErrorObject = {
  __typename?: 'DonationFormObjectSHrhhIWWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectSXrwncZ0 = {
  __typename?: 'DonationFormObjectSXrwncZ0';
  error?: Maybe<DonationFormObjectSXrwncZ0ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectSXrwncZ0ErrorObject = {
  __typename?: 'DonationFormObjectSXrwncZ0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectSttCfxUd = {
  __typename?: 'DonationFormObjectSttCfxUd';
  error?: Maybe<DonationFormObjectSttCfxUdErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectSttCfxUdErrorObject = {
  __typename?: 'DonationFormObjectSttCfxUdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectTSqsNveR = {
  __typename?: 'DonationFormObjectTSqsNveR';
  error?: Maybe<DonationFormObjectTSqsNveRErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectTSqsNveRErrorObject = {
  __typename?: 'DonationFormObjectTSqsNveRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectV6Nj5QaP = {
  __typename?: 'DonationFormObjectV6NJ5QaP';
  error?: Maybe<DonationFormObjectV6Nj5QaPErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectV6Nj5QaPErrorObject = {
  __typename?: 'DonationFormObjectV6NJ5QaPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectXi03Kj7w = {
  __typename?: 'DonationFormObjectXi03Kj7w';
  error?: Maybe<DonationFormObjectXi03Kj7wErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectXi03Kj7wErrorObject = {
  __typename?: 'DonationFormObjectXi03Kj7wErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectYRs1hask = {
  __typename?: 'DonationFormObjectYRs1hask';
  error?: Maybe<DonationFormObjectYRs1haskErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectYRs1haskErrorObject = {
  __typename?: 'DonationFormObjectYRs1haskErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectgV9cjrob = {
  __typename?: 'DonationFormObjectgV9cjrob';
  error?: Maybe<DonationFormObjectgV9cjrobErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectgV9cjrobErrorObject = {
  __typename?: 'DonationFormObjectgV9cjrobErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjecthjdaXttd = {
  __typename?: 'DonationFormObjecthjdaXttd';
  error?: Maybe<DonationFormObjecthjdaXttdErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjecthjdaXttdErrorObject = {
  __typename?: 'DonationFormObjecthjdaXttdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectrFhr8vdf = {
  __typename?: 'DonationFormObjectrFhr8vdf';
  error?: Maybe<DonationFormObjectrFhr8vdfErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectrFhr8vdfErrorObject = {
  __typename?: 'DonationFormObjectrFhr8vdfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectvTkXlwbQ = {
  __typename?: 'DonationFormObjectvTKXlwbQ';
  error?: Maybe<DonationFormObjectvTkXlwbQErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectvTkXlwbQErrorObject = {
  __typename?: 'DonationFormObjectvTKXlwbQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectwAKg883n = {
  __typename?: 'DonationFormObjectwAKg883n';
  error?: Maybe<DonationFormObjectwAKg883nErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObjectwAKg883nErrorObject = {
  __typename?: 'DonationFormObjectwAKg883nErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormRecurrence {
  monthly = 'monthly',
  weekly = 'weekly'
}

export type DonationFormResponseObject = {
  __typename?: 'DonationFormResponseObject';
  error?: Maybe<DonationFormResponseObjectErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormResponseObjectErrorObject = {
  __typename?: 'DonationFormResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormSummaryObject = {
  __typename?: 'DonationFormSummaryObject';
  description: Scalars['String'];
  id: Scalars['String'];
};

export type DonationFormTableResponseListObjectErrorObject = {
  __typename?: 'DonationFormTableResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormTemplateType {
  givingTuesday = 'givingTuesday',
  onboarding = 'onboarding'
}

export type DonationObject = {
  __typename?: 'DonationObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationForm: DonationFormObject;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastTransactionId?: Maybe<Scalars['String']>;
  nextPaymentDate?: Maybe<Scalars['Date']>;
  postalCode?: Maybe<Scalars['String']>;
  recurrence?: Maybe<DonationFormRecurrence>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  region?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type DonationResponseListObjectErrorObject = {
  __typename?: 'DonationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationResponseObjectErrorObject = {
  __typename?: 'DonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationCountry: OrganizationCountry;
};

export enum DonorType {
  Member = 'Member',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Prospect = 'Prospect',
  Returning = 'Returning',
  Yearly = 'Yearly'
}

export type DonorVolumeListResponseObject = {
  __typename?: 'DonorVolumeListResponseObject';
  error?: Maybe<DonorVolumeListResponseObjectErrorObject>;
  items?: Maybe<Array<DonorVolumeObject>>;
};

export type DonorVolumeListResponseObjectErrorObject = {
  __typename?: 'DonorVolumeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorVolumeObject = {
  __typename?: 'DonorVolumeObject';
  amount: Scalars['Float'];
  currency: AvailablePaymentCurrency;
};

export type EditContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type EditDiscountInput = {
  amount: Scalars['Float'];
  body: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  type: DiscountType;
};

export type EditDonationFormAmountInput = {
  toDelete: Array<DonationFormAmountInput>;
  toSave: Array<UpdateDonationFormAmountInput>;
};

export type EditDonationFormAmountTranslationInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type EditDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  donationFormFields: Array<EditDonationFormFieldInput>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<EditLinkedTicketingInput>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrence?: InputMaybe<DonationFormRecurrence>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['Float']>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export type EditDonationFormTranslationsInput = {
  fields: TranslationsDonationFormFieldInput;
  translations: TranslationsDonationFormFieldInput;
};

export type EditLinkedTicketingInput = {
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates: Array<EditRateInput>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
};

export type EditPasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type EditRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditRateInput = {
  allowAutomaticRenewal?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Float']>;
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields: Array<EditRateFieldInput>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type EditReminderDataInput = {
  id: Scalars['String'];
  reminderBody: Scalars['String'];
  reminderSubject: Scalars['String'];
};

export type EditSessionOnSubmission = {
  durationOnPage: Scalars['Float'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type EditTagInput = {
  tagId: Scalars['String'];
  value: Scalars['String'];
};

export type EditTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<Array<EditDiscountInput>>;
  discountsToDelete?: InputMaybe<Array<Scalars['String']>>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation?: InputMaybe<Scalars['Boolean']>;
  generateExtraDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  generateQrCode?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder?: InputMaybe<Array<QuestionInput>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates?: InputMaybe<Array<EditRateInput>>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  thermometerTarget?: InputMaybe<Scalars['Float']>;
  ticketingFields?: InputMaybe<Array<EditTicketingFieldInput>>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerPdf?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export enum EmailContentFlow {
  Builder = 'Builder',
  Custom = 'Custom',
  Quick = 'Quick'
}

export type EmailContentObject = {
  __typename?: 'EmailContentObject';
  attachmentUrl?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  emailType?: Maybe<EmailContentType>;
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  subject: Scalars['String'];
};

export enum EmailContentType {
  BLAST = 'BLAST',
  FOLLOW_UP = 'FOLLOW_UP',
  INVITATION = 'INVITATION',
  POST_EVENT = 'POST_EVENT',
  REMINDER = 'REMINDER'
}

export type EmailDeliveryObject = {
  __typename?: 'EmailDeliveryObject';
  body: Scalars['String'];
  clickedRatio: Scalars['Float'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<EmailContentObject>;
  emailContentId?: Maybe<Scalars['String']>;
  emailsCount: Scalars['Float'];
  id: Scalars['String'];
  isPrefill: Scalars['Boolean'];
  locale: Locales;
  openedRatio: Scalars['Float'];
  organization: OrganizationObject;
  recipientEmailStatus?: Maybe<EmailStatus>;
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  recipients?: Maybe<Array<RecipientObject>>;
  scheduledFor?: Maybe<Scalars['Date']>;
  sender: UserObject;
  sentAt?: Maybe<Scalars['Date']>;
  status: EmailDeliveryStatus;
  subject: Scalars['String'];
};

export type EmailDeliveryObjectXt3nnwzS = {
  __typename?: 'EmailDeliveryObjectXT3nnwzS';
  error?: Maybe<EmailDeliveryObjectXt3nnwzSErrorObject>;
  object?: Maybe<EmailDeliveryObject>;
};

export type EmailDeliveryObjectXt3nnwzSErrorObject = {
  __typename?: 'EmailDeliveryObjectXT3nnwzSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailDeliveryResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailDeliveryStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  Failed = 'Failed',
  Pending = 'Pending',
  Processing = 'Processing'
}

export type EmailDeliveryTableListResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailStatus {
  AwaitingProcessing = 'AwaitingProcessing',
  Bounced = 'Bounced',
  Clicked = 'Clicked',
  Delivered = 'Delivered',
  Invalid = 'Invalid',
  Opened = 'Opened',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum EmailTemplateErrorCode {
  NotFound = 'NotFound'
}

export type EmailTemplateObject = {
  __typename?: 'EmailTemplateObject';
  body: Scalars['String'];
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  subject: Scalars['String'];
  title: Scalars['String'];
};

export type EmailTemplateResponseListObject = {
  __typename?: 'EmailTemplateResponseListObject';
  error?: Maybe<EmailTemplateResponseListObjectErrorObject>;
  items?: Maybe<Array<EmailTemplateObject>>;
};

export type EmailTemplateResponseListObjectErrorObject = {
  __typename?: 'EmailTemplateResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailTemplateResponseObject = {
  __typename?: 'EmailTemplateResponseObject';
  error?: Maybe<EmailTemplateResponseObjectErrorObject>;
  object?: Maybe<EmailTemplateObject>;
};

export type EmailTemplateResponseObjectErrorObject = {
  __typename?: 'EmailTemplateResponseObjectErrorObject';
  code?: Maybe<EmailTemplateErrorCode>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailType {
  VisitorDailyEmail = 'VisitorDailyEmail',
  VisitorMonthlyEmail = 'VisitorMonthlyEmail',
  VisitorWeeklyEmail = 'VisitorWeeklyEmail'
}

export type EmailsCountByStatusObject = {
  __typename?: 'EmailsCountByStatusObject';
  count: Scalars['Float'];
  status: Scalars['String'];
};

export type EmailsCountByStatusTableListResponseObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObject';
  error?: Maybe<EmailsCountByStatusTableListResponseObjectErrorObject>;
  filteredCount: Scalars['Float'];
  hasMore: Scalars['Boolean'];
  items?: Maybe<Array<EmailsCountByStatusObject>>;
  totalCount: Scalars['Float'];
};

export type EmailsCountByStatusTableListResponseObjectErrorObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EncryptedPaymentIntentObject = {
  __typename?: 'EncryptedPaymentIntentObject';
  clientSecret: Scalars['String'];
  encryptedPaymentIntentId: Scalars['String'];
};

export type EncryptedPaymentIntentResponseObjectErrorObject = {
  __typename?: 'EncryptedPaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EventDateRange = {
  __typename?: 'EventDateRange';
  endDateUtc: Scalars['Date'];
  startDateUtc: Scalars['Date'];
};

export enum ExpectedAmountSize {
  Big = 'Big',
  Medium = 'Medium',
  Small = 'Small'
}

export type ExpiredEarlyBirdErrorObject = {
  __typename?: 'ExpiredEarlyBirdErrorObject';
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type ExportObject = {
  __typename?: 'ExportObject';
  export?: Maybe<Scalars['String']>;
  isExportSentByEmail: Scalars['Boolean'];
};

export type ExportObjectFvSwkBec = {
  __typename?: 'ExportObjectFvSWKBec';
  error?: Maybe<ExportObjectFvSwkBecErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportObjectFvSwkBecErrorObject = {
  __typename?: 'ExportObjectFvSWKBecErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExportParametersInput = {
  locale: Locales;
  timezone: Scalars['String'];
};

export type ExportResponseObject = {
  __typename?: 'ExportResponseObject';
  error?: Maybe<ExportResponseObjectErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportResponseObjectErrorObject = {
  __typename?: 'ExportResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExternalAccountInput = {
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type ExternalAccountObject = {
  __typename?: 'ExternalAccountObject';
  accountName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<AvailablePaymentCurrency>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};

export type ExternalAccountObject9lSjj2Tp = {
  __typename?: 'ExternalAccountObject9lSjj2Tp';
  error?: Maybe<ExternalAccountObject9lSjj2TpErrorObject>;
  object?: Maybe<ExternalAccountObject>;
};

export type ExternalAccountObject9lSjj2TpErrorObject = {
  __typename?: 'ExternalAccountObject9lSjj2TpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FeatureFlagName {
  FeatureToggleBadTipperDisclaimer = 'FeatureToggleBadTipperDisclaimer',
  IsAlgoliaForFormSearch = 'IsAlgoliaForFormSearch',
  IsAuctionRebiddingDisabled = 'IsAuctionRebiddingDisabled',
  IsBookADemoDisplayedWebflow = 'IsBookADemoDisplayedWebflow',
  IsCloudflareApiEnabled = 'IsCloudflareApiEnabled',
  IsFundraiserFormEditDisabled = 'IsFundraiserFormEditDisabled',
  IsHubspotActiveInStage = 'IsHubspotActiveInStage',
  IsNewMobileFormCreationFlow = 'IsNewMobileFormCreationFlow',
  IsPaymentByPadAllowed = 'IsPaymentByPadAllowed',
  IsZeffyUnderAttackFromCardTesting = 'IsZeffyUnderAttackFromCardTesting',
  LearnMoreOnTipMessage = 'LearnMoreOnTipMessage',
  Test = 'Test',
  TipSuggestionBigAmountsLargerThan400 = 'TipSuggestionBigAmountsLargerThan400',
  TipSuggestionBigAmountsLargerThan1000 = 'TipSuggestionBigAmountsLargerThan1000',
  TipSuggestionExperience = 'TipSuggestionExperience',
  TipSuggestionSmallAmounts = 'TipSuggestionSmallAmounts'
}

export type FeatureFlagTreatmentObject = {
  __typename?: 'FeatureFlagTreatmentObject';
  name: FeatureFlagName;
  treatment: Scalars['Boolean'];
};

export enum FeatureFlagValue {
  A = 'A',
  B = 'B',
  C = 'C',
  Control = 'Control',
  D = 'D',
  E = 'E',
  F = 'F'
}

export type FirstFormObject = {
  __typename?: 'FirstFormObject';
  formCategory: FormCategory;
  formStatus: FormStatus;
  formType: FormType;
  id: Scalars['String'];
  path: Scalars['String'];
};

export enum FormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  PeerToPeerCampaign = 'PeerToPeerCampaign',
  Shop = 'Shop'
}

export type FormField = {
  __typename?: 'FormField';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type FormInput = {
  formType: FormType;
  id: Scalars['String'];
};

export type FormObject = {
  __typename?: 'FormObject';
  fields: Array<FormField>;
  formLink: Scalars['String'];
  formType: FormType;
  id: Scalars['String'];
  volume: Scalars['Float'];
};

export enum FormStatus {
  Active = 'Active',
  Draft = 'Draft'
}

export type FormTitle = {
  __typename?: 'FormTitle';
  fields: Array<FormField>;
  id: Scalars['String'];
};

export type FormTitleListResponse = {
  __typename?: 'FormTitleListResponse';
  error?: Maybe<FormTitleListResponseErrorObject>;
  items?: Maybe<Array<FormTitle>>;
};

export type FormTitleListResponseErrorObject = {
  __typename?: 'FormTitleListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FormType {
  DonationForm = 'DonationForm',
  Ticketing = 'Ticketing'
}

export enum FrequencyFilter {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type FrontendFormObject = {
  __typename?: 'FrontendFormObject';
  auctionEndDate?: Maybe<Scalars['Date']>;
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerUrl?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  fields: Array<FormField>;
  formCategory: FormCategory;
  formType: FormType;
  generateQrCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isPrimaryCampaign?: Maybe<Scalars['Boolean']>;
  isPrimaryTeam?: Maybe<Scalars['Boolean']>;
  isRegistration?: Maybe<Scalars['Boolean']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  rates?: Maybe<Array<TicketingDetailsRate>>;
  seats?: Maybe<Scalars['Int']>;
  status: FormStatus;
  teamId?: Maybe<Scalars['String']>;
};

export type FrontendFormObjectFormObjectResponseObjectErrorObject = {
  __typename?: 'FrontendFormObjectFormObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseListObject = {
  __typename?: 'FrontendFormObjectResponseListObject';
  error?: Maybe<FrontendFormObjectResponseListObjectErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObjectResponseListObjectErrorObject = {
  __typename?: 'FrontendFormObjectResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseObject = {
  __typename?: 'FrontendFormObjectResponseObject';
  error?: Maybe<FrontendFormObjectFormObjectResponseObjectErrorObject>;
  object?: Maybe<FrontendFormObject>;
};

export type FrontendFormObjectmnNgnkt0 = {
  __typename?: 'FrontendFormObjectmnNGNKT0';
  error?: Maybe<FrontendFormObjectmnNgnkt0ErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObjectmnNgnkt0ErrorObject = {
  __typename?: 'FrontendFormObjectmnNGNKT0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FullChoiceErrorObject = {
  __typename?: 'FullChoiceErrorObject';
  id: Scalars['String'];
  remainingAnswers: Scalars['Float'];
};

export type FullRateErrorObject = {
  __typename?: 'FullRateErrorObject';
  id: Scalars['String'];
  remainingTickets: Scalars['Float'];
};

export type FundraiserDonationFormObject = {
  __typename?: 'FundraiserDonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isFundraiserFormEditDisabled: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type GetBalanceTransactionDetailsObject = {
  __typename?: 'GetBalanceTransactionDetailsObject';
  id: Scalars['String'];
  sourceIssuingTransaction?: Maybe<GetBalanceTransactionIssuingTransactionObject>;
  sourcePayment?: Maybe<GetBalanceTransactionPaymentObject>;
  sourcePayout?: Maybe<GetBalanceTransactionPayoutObject>;
};

export type GetBalanceTransactionDetailsObjectgap1cn4w = {
  __typename?: 'GetBalanceTransactionDetailsObjectgap1cn4w';
  error?: Maybe<GetBalanceTransactionDetailsObjectgap1cn4wErrorObject>;
  object?: Maybe<GetBalanceTransactionDetailsObject>;
};

export type GetBalanceTransactionDetailsObjectgap1cn4wErrorObject = {
  __typename?: 'GetBalanceTransactionDetailsObjectgap1cn4wErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetBalanceTransactionIssuingTransactionObject = {
  __typename?: 'GetBalanceTransactionIssuingTransactionObject';
  authorizationCreatedAtUtc: Scalars['Date'];
  city?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  merchantName: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type GetBalanceTransactionPaymentObject = {
  __typename?: 'GetBalanceTransactionPaymentObject';
  createdAtUtc: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  formId: Scalars['String'];
  formName: Scalars['String'];
  formType: FormType;
  lastName: Scalars['String'];
  paymentMethod: PaymentMethod;
};

export type GetBalanceTransactionPayoutObject = {
  __typename?: 'GetBalanceTransactionPayoutObject';
  arrivalDate: Scalars['Date'];
};

export type GetCurrentUserFrontendObject = {
  __typename?: 'GetCurrentUserFrontendObject';
  connectedAsAdministrator: Scalars['Boolean'];
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  currentOrganizationDetails?: Maybe<OrganizationDetailsObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasAnyBankPermission: Scalars['Boolean'];
  hasDismissedEmailInfobox: Scalars['Boolean'];
  hasDismissedEmailTemplatePrompt: Scalars['Boolean'];
  hasDismissedReferralPromptAtFirstPayout: Scalars['Boolean'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isBankChangeSmsMfaActivated: Scalars['Boolean'];
  isLoginSmsMfaActivated: Scalars['Boolean'];
  isMigratedOnStytch: Scalars['Boolean'];
  isOnboardedOnCheckinManager: Scalars['Boolean'];
  isTwoFactorActivated: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  organizationGroups: Array<OrganizationGroupObject>;
  phone?: Maybe<Scalars['String']>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  zeffyAdministrator: Scalars['Boolean'];
};

export type GetCurrentUserFrontendObject3ImqOVzp = {
  __typename?: 'GetCurrentUserFrontendObject3ImqOVzp';
  error?: Maybe<GetCurrentUserFrontendObject3ImqOVzpErrorObject>;
  object?: Maybe<GetCurrentUserFrontendObject>;
};

export type GetCurrentUserFrontendObject3ImqOVzpErrorObject = {
  __typename?: 'GetCurrentUserFrontendObject3ImqOVzpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetCurrentUserObject = {
  __typename?: 'GetCurrentUserObject';
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAdministrator: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationGroups: Array<OrganizationGroupObject>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
};

export type GetCurrentUserResponseObjectErrorObject = {
  __typename?: 'GetCurrentUserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetDonationFormsFromCampaignObject = {
  __typename?: 'GetDonationFormsFromCampaignObject';
  hasMore: Scalars['Boolean'];
  individual: Array<DonationFormFromCampaignObject>;
  team: Array<DonationFormFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObjectGam0oBie = {
  __typename?: 'GetDonationFormsFromCampaignObjectGAM0oBie';
  error?: Maybe<GetDonationFormsFromCampaignObjectGam0oBieErrorObject>;
  object?: Maybe<GetDonationFormsFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObjectGam0oBieErrorObject = {
  __typename?: 'GetDonationFormsFromCampaignObjectGAM0oBieErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum GetEmailDeliveryError {
  BelongToMyOtherOrganization = 'BelongToMyOtherOrganization'
}

export type GetEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'GetEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<GetEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetEphemeralKeyInput = {
  nonce: Scalars['String'];
};

export type GetEphemeralKeyObject = {
  __typename?: 'GetEphemeralKeyObject';
  ephemeralKeySecret: Scalars['String'];
};

export type GetEphemeralKeyObject01d3nVcb = {
  __typename?: 'GetEphemeralKeyObject01d3nVcb';
  error?: Maybe<GetEphemeralKeyObject01d3nVcbErrorObject>;
  object?: Maybe<GetEphemeralKeyObject>;
};

export type GetEphemeralKeyObject01d3nVcbErrorObject = {
  __typename?: 'GetEphemeralKeyObject01d3nVcbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetFirstTransactionCreatedAtUtcResponseObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObject';
  createdAtUtc?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
};

export type GetFirstTransactionCreatedAtUtcResponseObjectpnvEkxWj = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObjectpnvEkxWJ';
  error?: Maybe<GetFirstTransactionCreatedAtUtcResponseObjectpnvEkxWjErrorObject>;
  object?: Maybe<GetFirstTransactionCreatedAtUtcResponseObject>;
};

export type GetFirstTransactionCreatedAtUtcResponseObjectpnvEkxWjErrorObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObjectpnvEkxWJErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingCardObject = {
  __typename?: 'GetIssuingCardObject';
  issuingCard?: Maybe<IssuingCardObject>;
};

export type GetIssuingCardObjectCu2qJyxj = {
  __typename?: 'GetIssuingCardObjectCU2qJyxj';
  error?: Maybe<GetIssuingCardObjectCu2qJyxjErrorObject>;
  object?: Maybe<GetIssuingCardObject>;
};

export type GetIssuingCardObjectCu2qJyxjErrorObject = {
  __typename?: 'GetIssuingCardObjectCU2qJyxjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingRewardTransactionsObject = {
  __typename?: 'GetIssuingRewardTransactionsObject';
  amount: Scalars['Float'];
  disputeCount: Scalars['Float'];
  transactionCount: Scalars['Float'];
};

export type GetIssuingRewardTransactionsObjectTbwp6kAs = {
  __typename?: 'GetIssuingRewardTransactionsObjectTbwp6kAs';
  error?: Maybe<GetIssuingRewardTransactionsObjectTbwp6kAsErrorObject>;
  object?: Maybe<GetIssuingRewardTransactionsObject>;
};

export type GetIssuingRewardTransactionsObjectTbwp6kAsErrorObject = {
  __typename?: 'GetIssuingRewardTransactionsObjectTbwp6kAsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetMarkerHoverDetailsInput = {
  cursorType: CursorType;
  id: Scalars['String'];
};

export type GetMarkersInput = {
  lat: LatLngBoundariesInput;
  lng: LatLngBoundariesInput;
};

export type GetOrganizationSummaryObject = {
  __typename?: 'GetOrganizationSummaryObject';
  address: Scalars['String'];
  lastDonationForm?: Maybe<DonationFormSummaryObject>;
  lastTicketing?: Maybe<TicketingSummaryObject>;
  url?: Maybe<Scalars['String']>;
};

export type GetOrganizationSummaryResponseObject = {
  __typename?: 'GetOrganizationSummaryResponseObject';
  error?: Maybe<GetOrganizationSummaryResponseObjectErrorObject>;
  object?: Maybe<GetOrganizationSummaryObject>;
};

export type GetOrganizationSummaryResponseObjectErrorObject = {
  __typename?: 'GetOrganizationSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetReferredOrganizationInput = {
  organizationId: Scalars['String'];
};

export type GetScheduledPayoutObject = {
  __typename?: 'GetScheduledPayoutObject';
  payout?: Maybe<ScheduledPayoutObject>;
};

export type GetScheduledPayoutObject3ZpGgN3O = {
  __typename?: 'GetScheduledPayoutObject3ZPGgN3O';
  error?: Maybe<GetScheduledPayoutObject3ZpGgN3OErrorObject>;
  object?: Maybe<GetScheduledPayoutObject>;
};

export type GetScheduledPayoutObject3ZpGgN3OErrorObject = {
  __typename?: 'GetScheduledPayoutObject3ZPGgN3OErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetTipSuggestionsInput = {
  amount: Scalars['Float'];
  tipModelExperience?: InputMaybe<TipModelExperience>;
};

export type GetTransactionsForIssuingBalanceTransferObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject';
  endDate?: Maybe<Scalars['Date']>;
  volume: Scalars['Float'];
};

export type GetTransactionsForIssuingBalanceTransferObject1JMcfLnW = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject1JMcfLnW';
  error?: Maybe<GetTransactionsForIssuingBalanceTransferObject1JMcfLnWErrorObject>;
  object?: Maybe<GetTransactionsForIssuingBalanceTransferObject>;
};

export type GetTransactionsForIssuingBalanceTransferObject1JMcfLnWErrorObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject1JMcfLnWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HasEmailDeliveryObject = {
  __typename?: 'HasEmailDeliveryObject';
  draft: Scalars['Boolean'];
  pending: Scalars['Boolean'];
  sent: Scalars['Boolean'];
};

export type HasEmailDeliveryObjectB29Dd8jp = {
  __typename?: 'HasEmailDeliveryObjectB29DD8jp';
  error?: Maybe<HasEmailDeliveryObjectB29Dd8jpErrorObject>;
  object?: Maybe<HasEmailDeliveryObject>;
};

export type HasEmailDeliveryObjectB29Dd8jpErrorObject = {
  __typename?: 'HasEmailDeliveryObjectB29DD8jpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  frequency: FrequencyFilter;
  startDate: Scalars['Date'];
};

export type HomeGraphObject = {
  __typename?: 'HomeGraphObject';
  date: Scalars['Date'];
  volume: Scalars['Int'];
};

export type HomeGraphObjectResponseObjectErrorObject = {
  __typename?: 'HomeGraphObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphResponseListObject = {
  __typename?: 'HomeGraphResponseListObject';
  error?: Maybe<HomeGraphResponseListObjectErrorObject>;
  items?: Maybe<Array<HomeGraphObject>>;
};

export type HomeGraphResponseListObjectErrorObject = {
  __typename?: 'HomeGraphResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeMetricsInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type HubspotProperty = {
  propertyName: Scalars['String'];
  propertyValue: Scalars['String'];
};

export type ImportCheckErrorObject = {
  __typename?: 'ImportCheckErrorObject';
  emptyColumns: Array<Scalars['String']>;
  invalidColumns: Array<Scalars['String']>;
  row: Scalars['Float'];
};

export enum ImportStatus {
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum ImportType {
  Contact = 'Contact',
  Payment = 'Payment'
}

export type ImportUsersGoogleSheetObject = {
  __typename?: 'ImportUsersGoogleSheetObject';
  addedContactCount?: Maybe<Scalars['Float']>;
  addedPaymentCount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ImportCheckErrorObject>>;
  id?: Maybe<Scalars['String']>;
  isAsync?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ImportStatus>;
};

export type ImportUsersGoogleSheetObjectOMymSkMf = {
  __typename?: 'ImportUsersGoogleSheetObjectOMymSkMf';
  error?: Maybe<ImportUsersGoogleSheetObjectOMymSkMfErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObjectOMymSkMfErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObjectOMymSkMfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetObjectaHgxiXeK = {
  __typename?: 'ImportUsersGoogleSheetObjectaHgxiXeK';
  error?: Maybe<ImportUsersGoogleSheetObjectaHgxiXeKErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObjectaHgxiXeKErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObjectaHgxiXeKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetResponseObjectErrorObject = {
  __typename?: 'ImportUsersGoogleSheetResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IncreasePayoutAmountInput = {
  amount: Scalars['Float'];
  payoutId: Scalars['String'];
};

export type IncreasePayoutAmountObject = {
  __typename?: 'IncreasePayoutAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type IncreasePayoutAmountObjectnu3pglkn = {
  __typename?: 'IncreasePayoutAmountObjectnu3pglkn';
  error?: Maybe<IncreasePayoutAmountObjectnu3pglknErrorObject>;
  object?: Maybe<IncreasePayoutAmountObject>;
};

export type IncreasePayoutAmountObjectnu3pglknErrorObject = {
  __typename?: 'IncreasePayoutAmountObjectnu3pglknErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type InsertTagInput = {
  tagId: Scalars['String'];
  userId: Scalars['String'];
};

export type IntResponseObject = {
  __typename?: 'IntResponseObject';
  error?: Maybe<IntegerResponseObjectErrorObject>;
  object?: Maybe<Scalars['Int']>;
};

export type IntegerResponseObjectErrorObject = {
  __typename?: 'IntegerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IsEmailRegisteredInput = {
  email: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
  redirectionUrl?: InputMaybe<Scalars['String']>;
};

export type IsEmailRegisteredSsoInput = {
  googleToken: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
};

export type IsUserEmailRegisteredObject = {
  __typename?: 'IsUserEmailRegisteredObject';
  fundraiserCreationToken?: Maybe<Scalars['String']>;
  hadPasswordInSimplykV1: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  hasResetPasswordToken: Scalars['Boolean'];
  shouldAuthenticateWithGoogle?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  userRegistrationStatus: UserRegistrationStatus;
};

export type IsUserEmailRegisteredResponseObject = {
  __typename?: 'IsUserEmailRegisteredResponseObject';
  error?: Maybe<IsUserEmailRegisteredResponseObjectErrorObject>;
  object?: Maybe<IsUserEmailRegisteredObject>;
};

export type IsUserEmailRegisteredResponseObjectErrorObject = {
  __typename?: 'IsUserEmailRegisteredResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IssuingCardObject = {
  __typename?: 'IssuingCardObject';
  cardHolderStatus: CardHolderStatus;
  id: Scalars['String'];
  isCardHolder: Scalars['Boolean'];
  last4?: Maybe<Scalars['String']>;
  status: IssuingCardStatus;
  stripeIssuingCardId?: Maybe<Scalars['String']>;
  treasuryFinancialAccountId?: Maybe<Scalars['String']>;
};

export enum IssuingCardStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type LatLngBoundariesInput = {
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type ListTransactionTicketsObject = {
  __typename?: 'ListTransactionTicketsObject';
  allCanceled: Scalars['Boolean'];
  productTickets: Array<ProductTicketObject>;
};

export type ListTransactionTicketsObjectErrorObject = {
  __typename?: 'ListTransactionTicketsObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ListTransactionTicketsResponse = {
  __typename?: 'ListTransactionTicketsResponse';
  error?: Maybe<ListTransactionTicketsObjectErrorObject>;
  object?: Maybe<ListTransactionTicketsObject>;
};

export enum Locales {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type ManualContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  note?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MarkerHoverDetailsObject = {
  __typename?: 'MarkerHoverDetailsObject';
  color?: Maybe<Scalars['String']>;
  cursorType: CursorType;
  dates: Array<EventDateRange>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MarkerPositionObject = {
  __typename?: 'MarkerPositionObject';
  coordinates: CoordinatesObject;
  cursorType: CursorType;
  id: Scalars['String'];
};

export type MarkerPositionResponseObjectErrorObject = {
  __typename?: 'MarkerPositionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum MembershipValidityPeriod {
  AtDate = 'AtDate',
  NoExpiration = 'NoExpiration',
  OneMonthAfterPayment = 'OneMonthAfterPayment',
  OneYearAfterPayment = 'OneYearAfterPayment'
}

export enum MergeUsersError {
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  UserNotFound = 'UserNotFound'
}

export type MergeUsersObject = {
  __typename?: 'MergeUsersObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type MergeUsersObjectQttdLcar = {
  __typename?: 'MergeUsersObjectQttdLcar';
  error?: Maybe<MergeUsersObjectQttdLcarErrorObject>;
  object?: Maybe<MergeUsersObject>;
};

export type MergeUsersObjectQttdLcarErrorObject = {
  __typename?: 'MergeUsersObjectQttdLcarErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectLabelObject = {
  __typename?: 'MultiselectLabelObject';
  label: Scalars['String'];
  locale: Locales;
};

export type MultiselectOccurrenceValueObject = {
  __typename?: 'MultiselectOccurrenceValueObject';
  endUtc: Scalars['Date'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  value: Scalars['String'];
};

export type MultiselectOccurrenceValueObjectk5uuOmRw = {
  __typename?: 'MultiselectOccurrenceValueObjectk5uuOmRw';
  error?: Maybe<MultiselectOccurrenceValueObjectk5uuOmRwErrorObject>;
  items?: Maybe<Array<MultiselectOccurrenceValueObject>>;
};

export type MultiselectOccurrenceValueObjectk5uuOmRwErrorObject = {
  __typename?: 'MultiselectOccurrenceValueObjectk5uuOmRwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObject = {
  __typename?: 'MultiselectValueObject';
  labels: Array<MultiselectLabelObject>;
  value: Scalars['String'];
};

export type MultiselectValueObjectZdTnhvey = {
  __typename?: 'MultiselectValueObjectZdTnhvey';
  error?: Maybe<MultiselectValueObjectZdTnhveyErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjectZdTnhveyErrorObject = {
  __typename?: 'MultiselectValueObjectZdTnhveyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObjects32uVrkW = {
  __typename?: 'MultiselectValueObjects32uVrkW';
  error?: Maybe<MultiselectValueObjects32uVrkWErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjects32uVrkWErrorObject = {
  __typename?: 'MultiselectValueObjects32uVrkWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueResponseListObject = {
  __typename?: 'MultiselectValueResponseListObject';
  error?: Maybe<MultiselectValueResponseListObjectErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueResponseListObjectErrorObject = {
  __typename?: 'MultiselectValueResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCardIssuing: BooleanpWm7ZKb3;
  activateDonationForm: DonationFormObjectSttCfxUd;
  activateTicketing: TicketingObject3Nwnx08I;
  activeTwoFactorAuth: Boolean0NLtHXr6;
  addEngagementStepChecked: BooleanOkkpzWoO;
  addManualContact: StringC3ycm82r;
  addRetroActiveReferral: BooleanBh28wO1P;
  addTicketingOffset: BooleanxrxL14f1;
  archiveCampaign: Number8a9pSxFf;
  archiveDonationForm: BooleantgdCrqQq;
  archiveTeam: Boolean64hOmGlN;
  archiveTicketing: BooleanITfQt5og;
  cancelCommandSubscription: CommandObjectg1z1rFnF;
  cancelDonorCommandSubscription: CommandObject5IXkLnyr;
  cancelReceipt: ReceiptObjectcOx0gKpb;
  capturePaymentIntent: BooleanResponseObject;
  changeContactEmailSubscription: BooleanBJvxqpDw;
  checkinProductTicket: Booleanglh5Egor;
  checkinProductTicketByCommandId: BooleanVic2JlOc;
  closeTicketing: BooleanFt4vsewb;
  confirmPaymentIntent: CommandObject8kjDqt0y;
  createBalanceTransferToIssuing: BooleanyylcEate;
  createDonationFormFromTemplate: StringZBhfDhXk;
  createDonorFormTranslations: DonationFormObjectSHrhhIww;
  createDraftCampaign: DonationFormObjectAtgNqflE;
  createDraftDonationForm: DonationFormObjectgV9cjrob;
  createDraftTicketing: StringCeNtg3eW;
  createFormTranslations: DonationFormObject90bxPxuM;
  createFrontendDonor: UserResponseObject;
  createFundraiserFormTranslations: DonationFormObjectXi03Kj7w;
  createIssuingPayout: CreatedIssuingPayoutObjectAzZpVyDk;
  createIssuingTopup: BooleanM3Fjcbdm;
  createNewOrganization: AccessTokenObjectO7muNxRz;
  createNote: NoteResponseObject;
  createPlaidLinkToken: StringyH6QHd2v;
  createPreviewReceipt: Stringca9Yb62c;
  createReceipt: ReceiptResponseObject;
  createSampleContact: ContactObjectSkL0EWhW;
  createSampleTransaction: TransactionObject4dRXj424;
  createStripeCheckoutSession: CheckoutSessionResponseObject;
  createStripeCustomExternalAccount: ExternalAccountObject9lSjj2Tp;
  createTags: StringListResponseObject;
  createTicketingCardPaymentIntent: PaymentIntentResponseObject;
  deactivateOrganization: BooleanResponseObject;
  deactiveTwoFactorAuth: BooleansBohXvUt;
  deleteCampaign: NumberC180Sqb1;
  deleteContact: BooleanResponseObject;
  deleteDonationForm: BooleanyWMogBbI;
  deleteEmailTemplate: EmailTemplateResponseObject;
  deleteMemberToOrganizationAdministrators: UserObjectrgAOud2A;
  deleteNote?: Maybe<StringResponseObject>;
  deleteSampleContact: BooleanQkcq0Y1c;
  deleteSampleTransaction: BooleandlvRAnkw;
  deleteTagForAllUsers: BooleanResponseObject;
  deleteTags: BooleanResponseObject;
  deleteTeam: Booleany7Bh7sTe;
  deleteTicketing: Booleanca6Yn8yI;
  deleteUser: Booleanq9ldXDrB;
  disableGoogleSsoLogin: BooleanIsE3y9S0;
  dismissActivationPayoutSuccessCard: BooleanrmldcJaf;
  displayEngagementChecklist: BooleanFibQ09Ub;
  editAnswer: AnswerResponseObject;
  editCommandSubscriptionAmount: CommandResponseObject;
  editContact: ContactResponseObject;
  editContactEmail: UserObjectc8TlysIn;
  editDonationCommandForm: CommandResponseObject;
  editDonorCommandSubscriptionAmount: CommandResponseObject;
  editDonorFormAdvancedParameters: DonationFormObjectNuMsbHct;
  editDonorFormCustomization: DonationFormObjectFxlVJfuG;
  editDonorFormGeneralInformations: DonationFormObjectTSqsNveR;
  editFormAdvancedParameters: DonationFormObjectrFhr8vdf;
  editFormCustomization: DonationFormObject5lxUqFwg;
  editFormGeneralInformations: DonationFormObjectSXrwncZ0;
  editFundraiserFormAdvancedParameters: DonationFormObjectvTkXlwbQ;
  editFundraiserFormCustomization: DonationFormObjectV6Nj5QaP;
  editFundraiserFormGeneralInformations: DonationFormObject2qRuNxdg;
  editNote?: Maybe<NoteResponseObject>;
  editPassword: UserObjecteqXSo0o2;
  editTag: BooleanResponseObject;
  editTicketingAdvancedParameters: TicketingObjectvYjz3Rof;
  editTicketingEventCustomization: TicketingObjectb9VaPert;
  editTicketingGeneralInformation: TicketingObjectRBwXowgY;
  editTicketingRates: TicketingObject5rcbrIgw;
  editTicketingReminderData: TicketingObjectfN9FeFy5;
  editUnsubscribePreferences: BooleanO9zZto7m;
  exportBalanceTransactionsTable: ExportResponseObject;
  exportDonorReceiptTable: ExportResponseObject;
  exportFundraiserList: ExportObjectFvSwkBec;
  exportPayoutsTable: ExportResponseObject;
  exportTransactionsTable: ExportResponseObject;
  generatePreviewETicket: StringResponseObject;
  getCannyToken: StringResponseObject;
  getDiscountCodeFromTicketingIdAndBody: DiscountResponseObject;
  increasePayoutAmount: IncreasePayoutAmountObjectnu3pglkn;
  insertTags: BooleanResponseObject;
  isUserEmailRegistered: IsUserEmailRegisteredResponseObject;
  isUserEmailRegisteredSSO: AccessTokenObject942fKilK;
  launchImport: ImportUsersGoogleSheetObjectaHgxiXeK;
  mergeTeams: BooleanuaIyxoCt;
  mergeUsers: MergeUsersObjectQttdLcar;
  moveParticipantToCampaign: Boolean8H9gr810;
  openTicketing: TicketingObjectAkNtLdR7;
  postSubmitCommand: PostSubmitCommandObjectZpkqymmk;
  processIssuingRewards: Boolean2g5b0dx8;
  processPayout: BooleanXjFaaesd;
  purgeAllCache: BooleanJz1f21qE;
  reOpenCommand: BooleaneRrDcavO;
  refundTip: TransactionObjectM2c3a2Fm;
  refundTransaction: RefundResponseObject;
  removeEngagementChecklist: BooleanbQvj90Hv;
  requestIssuingCard: BooleanFcdGvcU2;
  resendTransactionReceipt?: Maybe<StringResponseObject>;
  resendTwoFactorCode: BooleanHiy9J4Iv;
  resetEngagementStepChecked: Booleancirl0Wvo;
  resetPassword: AccessTokenObjectXoaZ2zhb;
  reverseDonationAnonymous: BooleanM5tiwNkd;
  saveCardOrder: CreateOrderResponseObject;
  saveDonationThankYouComment: CommandResponseObject;
  saveV1MobileCommand: CreateMobileCommandResponseObject;
  scanTicket: ProductTicketObjectSi17z4j2;
  sendMergeUserRequest: SendMergeUsersRequestResponseObject;
  sendMonthlyBalanceTransactionExport: Booleand9mwI22L;
  sendResetPasswordRequest: ResetPasswordLinkResponseObject;
  sendResetPasswordRequestMobile: ResetPasswordLinkResponseObject;
  sendTransactionReceiptForExistingCommand: CommandResponseObject;
  sendTwoFactorCode: BooleanpvXgVy9L;
  sendVerifyEmailNotification: Booleand2kQ7m0b;
  setHasDismissedEmailInfobox: BooleanDDwUtZgj;
  setHasDismissedEmailTemplatePrompt: Boolean2NOkXnud;
  setHasDismissedReferralPrompt: BooleannxnEpfWu;
  setHasSeenReferralPage: BooleanResponseObject;
  setUserAsOnboardedOnCheckinManager: BooleannnzdTqAt;
  setUserCurrentOrganization: AccessTokenObjectR6PuXmaJ;
  setUserCurrentOrganizationMobile: AccessTokenObject;
  setVolumeThresholdReached: OrganizationResponseObject;
  signIn: AccessTokenObject5csoj1Lr;
  signInAsAdministrator: AccessTokenObjectbfkleYbd;
  signInMobile?: Maybe<AccessTokenObject>;
  signInMobileWithGoogle?: Maybe<AccessTokenObject>;
  signOut: BooleanHc5Bq7Xf;
  signUp: AccessTokenObjectKZot5p63;
  switchOrgAsAdministrator: AccessTokenResponseObject;
  testModeAutomateOnboardingToStripe: BooleanResponseObject;
  trackDownload?: Maybe<Scalars['Boolean']>;
  triggerManualCron: Boolean6fDMg4My;
  unarchiveCampaign: Number29Nker9k;
  unarchiveDonationForm: BooleanAooArEq8;
  unarchiveTicketing: BooleanlzZpKuKk;
  unsubscribe: ContactResponseObject;
  unsubscribeUserFromEmails: BooleangIpUfPgu;
  updateAccessTokenWithTargetedOrganization: BooleanH7j89lNk;
  updateAdministrativeInformation: OrganizationObject0rCgaq3w;
  updateAdministrativeMoreInformation: OrganizationObjectJuu6iIdU;
  updateAmountsFromToken: Stringw60KaPwd;
  updateCompanyOnboardingQualification: BooleanResponseObject;
  updateHubspotContactProperty: BooleansqtkkQcU;
  updateOrganizationCategory: BooleanResponseObject;
  updateOrganizationEngagementFlags: BooleanResponseObject;
  updateOrganizationFlags: BooleanjWmF2qxi;
  updateOrganizationHubspotProperty: BooleanResponseObject;
  updateOrganizationOnboardedFormCategory: Boolean1vD1GgBi;
  updateOrganizationWithStripeCustomFlowHasStarted: BooleanPpQik8eu;
  updatePaymentIntent: UpdatePaymentIntentResponse;
  updatePayoutInterval: StripeAccountResponseObject;
  updateProductDonationAnnotation: BooleanwOrSjKFx;
  updateProductDonationAnonymous: Boolean6V9877ec;
  updateProductTicketAnnotation: BooleanResponseObject;
  uploadChunkForImport: ChunkImportObjectpD0Zyuux;
  upsertSession: SessionResponseObject;
  verifyEmail: VerifyEmailObjectcCEcIpfN;
  verifyTwoFactorCode: BooleangcYAwwDi;
};


export type MutationActivateDonationFormArgs = {
  activateDonationFormInput: ActivateDonationFormInput;
};


export type MutationActivateTicketingArgs = {
  activateTicketingInput: ActivateTicketingInput;
};


export type MutationAddEngagementStepCheckedArgs = {
  activationStepId: Scalars['String'];
};


export type MutationAddManualContactArgs = {
  manualContactInput: ManualContactInput;
};


export type MutationAddRetroActiveReferralArgs = {
  retroActiveReferralInput: RetroActiveReferralInput;
};


export type MutationAddTicketingOffsetArgs = {
  offset: Scalars['Int'];
  ticketingIdOrPath: Scalars['String'];
};


export type MutationArchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationArchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationArchiveTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationArchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationCancelCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelDonorCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelReceiptArgs = {
  receiptId: Scalars['String'];
};


export type MutationCapturePaymentIntentArgs = {
  paymentIntentId: Scalars['String'];
};


export type MutationChangeContactEmailSubscriptionArgs = {
  unsubscribe: Scalars['Boolean'];
  userEmails: Array<Scalars['String']>;
};


export type MutationCheckinProductTicketArgs = {
  isCheckin: Scalars['Boolean'];
  productTicketId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheckinProductTicketByCommandIdArgs = {
  commandId: Scalars['String'];
  isCheckin: Scalars['Boolean'];
  token: Scalars['String'];
};


export type MutationCloseTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationConfirmPaymentIntentArgs = {
  confirmPaymentIntentInput: ConfirmPaymentIntentInput;
};


export type MutationCreateBalanceTransferToIssuingArgs = {
  input: ProcessBalanceTransferInput;
};


export type MutationCreateDonationFormFromTemplateArgs = {
  template: DonationFormTemplateType;
};


export type MutationCreateDonorFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateDraftCampaignArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftDonationFormArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftTicketingArgs = {
  ticketing: CreateTicketingInput;
};


export type MutationCreateFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateFrontendDonorArgs = {
  donor: DonorInput;
};


export type MutationCreateFundraiserFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
  token: Scalars['String'];
};


export type MutationCreateIssuingPayoutArgs = {
  input: CreatedIssuingPayoutInput;
};


export type MutationCreateIssuingTopupArgs = {
  input: CreateIssuingTopupInput;
};


export type MutationCreateNewOrganizationArgs = {
  newOrganizationInput: CreateNewOrganizationInput;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreatePreviewReceiptArgs = {
  previewReceiptParams: PreviewReceiptParams;
};


export type MutationCreateReceiptArgs = {
  receipt: CreateReceiptInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  customerId: Scalars['String'];
  subscriptionId: Scalars['String'];
};


export type MutationCreateStripeCustomExternalAccountArgs = {
  externalAccountInput: ExternalAccountInput;
};


export type MutationCreateTagsArgs = {
  tag: CreateTagInput;
};


export type MutationCreateTicketingCardPaymentIntentArgs = {
  paymentIntent: TicketingPaymentIntentInput;
};


export type MutationDeactivateOrganizationArgs = {
  input: DeactivateAccountInput;
};


export type MutationDeactiveTwoFactorAuthArgs = {
  param: DeactiveTwoFactorAuthInput;
};


export type MutationDeleteCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationDeleteContactArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteDonationFormArgs = {
  donationFormId: Scalars['String'];
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteEmailTemplateArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMemberToOrganizationAdministratorsArgs = {
  email: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  noteId: Scalars['String'];
};


export type MutationDeleteSampleContactArgs = {
  contactId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteSampleTransactionArgs = {
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteTagForAllUsersArgs = {
  tagId: Scalars['String'];
};


export type MutationDeleteTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationDeleteTicketingArgs = {
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
  ticketingId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  input: DeleteUserAccountInput;
};


export type MutationDisableGoogleSsoLoginArgs = {
  userEmail: Scalars['String'];
};


export type MutationEditAnswerArgs = {
  answerInput: AnswerInput;
};


export type MutationEditCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditContactArgs = {
  editContactInput: EditContactInput;
  userId: Scalars['String'];
};


export type MutationEditContactEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationEditDonationCommandFormArgs = {
  donationFormId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationEditDonorCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditDonorFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFundraiserFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditNoteArgs = {
  content: Scalars['String'];
  noteId: Scalars['String'];
};


export type MutationEditPasswordArgs = {
  editPasswordInput: EditPasswordInput;
};


export type MutationEditTagArgs = {
  tag: EditTagInput;
};


export type MutationEditTicketingAdvancedParametersArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingEventCustomizationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingGeneralInformationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingRatesArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingReminderDataArgs = {
  data: EditReminderDataInput;
};


export type MutationEditUnsubscribePreferencesArgs = {
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
};


export type MutationExportBalanceTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<BalanceTransactionExportFilter>;
};


export type MutationExportDonorReceiptTableArgs = {
  exportParameters: ExportParametersInput;
  year: Scalars['Float'];
};


export type MutationExportFundraiserListArgs = {
  donationFormId: Scalars['String'];
  exportParameters: ExportParametersInput;
};


export type MutationExportPayoutsTableArgs = {
  exportParameters: ExportParametersInput;
};


export type MutationExportTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<TransactionExportFilter>;
};


export type MutationGeneratePreviewETicketArgs = {
  ticketPreviewParams: PreviewTicketParams;
};


export type MutationGetDiscountCodeFromTicketingIdAndBodyArgs = {
  discountCodeBody: Scalars['String'];
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type MutationIncreasePayoutAmountArgs = {
  input: IncreasePayoutAmountInput;
};


export type MutationInsertTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationIsUserEmailRegisteredArgs = {
  isEmailRegisteredInput: IsEmailRegisteredInput;
};


export type MutationIsUserEmailRegisteredSsoArgs = {
  isEmailRegisteredSSOInput: IsEmailRegisteredSsoInput;
};


export type MutationLaunchImportArgs = {
  base64: Scalars['String'];
  rowNum: Scalars['Float'];
  type: ImportType;
};


export type MutationMergeTeamsArgs = {
  teamToBeMergedIdOrPath: Scalars['String'];
  teamToMergeInIdOrPath: Scalars['String'];
};


export type MutationMergeUsersArgs = {
  encryptedToken: Scalars['String'];
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationMoveParticipantToCampaignArgs = {
  participantIdOrPath: Scalars['String'];
  targetedTeamIdOrPath: Scalars['String'];
};


export type MutationOpenTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationPostSubmitCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationProcessPayoutArgs = {
  payoutId: Scalars['String'];
};


export type MutationReOpenCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationRefundTipArgs = {
  amountToRefund: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationRefundTransactionArgs = {
  amountToRefund: Scalars['Float'];
  cancelTaxReceipt: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationRequestIssuingCardArgs = {
  cardHolderInput: CreateCardHolderInput;
};


export type MutationResendTransactionReceiptArgs = {
  email: Scalars['String'];
  transactionId: Scalars['String'];
};


export type MutationResendTwoFactorCodeArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationReverseDonationAnonymousArgs = {
  donationFormIdOrPath: Scalars['String'];
  donorEmail: Scalars['String'];
};


export type MutationSaveCardOrderArgs = {
  editSessionInput: EditSessionOnSubmission;
  order: CreateOrderInput;
  ticketingId: Scalars['String'];
  tickets: Array<CreateTicketInput>;
};


export type MutationSaveDonationThankYouCommentArgs = {
  commandId: Scalars['String'];
  thankYouComment: Scalars['String'];
};


export type MutationSaveV1MobileCommandArgs = {
  createMobileCommandInput: CreateMobileCommandInput;
};


export type MutationScanTicketArgs = {
  ticketIdInput: UuidInput;
};


export type MutationSendMergeUserRequestArgs = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationSendResetPasswordRequestArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendResetPasswordRequestMobileArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendTransactionReceiptForExistingCommandArgs = {
  commandId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSendVerifyEmailNotificationArgs = {
  redirectionUrl?: InputMaybe<Scalars['String']>;
};


export type MutationSetUserCurrentOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetUserCurrentOrganizationMobileArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetVolumeThresholdReachedArgs = {
  volumeThresholdReachedInput: UpdateVolumeThresholdReached;
};


export type MutationSignInArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationSignInAsAdministratorArgs = {
  signInAsAdministratorInput: SignInAsAdministratorInput;
};


export type MutationSignInMobileArgs = {
  signInInput: SignInInput;
};


export type MutationSignInMobileWithGoogleArgs = {
  googleToken: Scalars['String'];
};


export type MutationSignUpArgs = {
  signUpInput: SignUpInput;
};


export type MutationSwitchOrgAsAdministratorArgs = {
  switchOrgAsAdministratorInput: SwitchOrgAsAdministratorInput;
};


export type MutationTrackDownloadArgs = {
  location: Scalars['String'];
};


export type MutationTriggerManualCronArgs = {
  cronName: CronQueues;
  delay?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUnarchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationUnarchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationUnarchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationUnsubscribeArgs = {
  contactId: Scalars['String'];
  emailDeliveryId: Scalars['String'];
};


export type MutationUnsubscribeUserFromEmailsArgs = {
  organizationId: Scalars['String'];
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
  userId: Scalars['String'];
};


export type MutationUpdateAccessTokenWithTargetedOrganizationArgs = {
  targetOrganizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAdministrativeInformationArgs = {
  updateAdministrativeInformationInput: UpdateAdministrativeInformationInput;
};


export type MutationUpdateAdministrativeMoreInformationArgs = {
  updateAdministrativeMoreInformationInput: UpdateAdministrativeMoreInformationInput;
};


export type MutationUpdateAmountsFromTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateCompanyOnboardingQualificationArgs = {
  qualificationInput: OnboardingQualificationInput;
};


export type MutationUpdateHubspotContactPropertyArgs = {
  payload: HubspotProperty;
};


export type MutationUpdateOrganizationCategoryArgs = {
  category: OrganizationCategory;
};


export type MutationUpdateOrganizationEngagementFlagsArgs = {
  updateOrganizationEngagementFlagsInput: UpdateOrganizationEngagementFlagsInput;
};


export type MutationUpdateOrganizationFlagsArgs = {
  input: OrganizationFlagsInput;
};


export type MutationUpdateOrganizationHubspotPropertyArgs = {
  input: UpdateOrganizationHubspotPropertyInput;
};


export type MutationUpdateOrganizationOnboardedFormCategoryArgs = {
  onboardedFormCategory: FormCategory;
};


export type MutationUpdatePaymentIntentArgs = {
  params: UpdatePaymentIntentInput;
};


export type MutationUpdatePayoutIntervalArgs = {
  updatePayoutInterval: UpdatePayoutInterval;
};


export type MutationUpdateProductDonationAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productDonationId: Scalars['String'];
};


export type MutationUpdateProductDonationAnonymousArgs = {
  productDonationId: Scalars['String'];
  value?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateProductTicketAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productTicketId: Scalars['String'];
};


export type MutationUploadChunkForImportArgs = {
  chunk: Scalars['String'];
  chunkIndex: Scalars['Float'];
  importId?: InputMaybe<Scalars['String']>;
  isLastChunk: Scalars['Boolean'];
  type: ImportType;
};


export type MutationUpsertSessionArgs = {
  sessionInput: UpsertSessionInput;
};


export type MutationVerifyEmailArgs = {
  email: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};


export type MutationVerifyTwoFactorCodeArgs = {
  value: Scalars['String'];
};

export type MyReferralOrganizationObject = {
  __typename?: 'MyReferralOrganizationObject';
  count: Scalars['Float'];
  email: Scalars['String'];
  id: Scalars['String'];
  isStripeCustomAccountActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type MyReferralOrganizationObjectmUiG4F5H = {
  __typename?: 'MyReferralOrganizationObjectmUiG4F5H';
  error?: Maybe<MyReferralOrganizationObjectmUiG4F5HErrorObject>;
  items?: Maybe<Array<MyReferralOrganizationObject>>;
};

export type MyReferralOrganizationObjectmUiG4F5HErrorObject = {
  __typename?: 'MyReferralOrganizationObjectmUiG4F5HErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MyReferrerOrganizationObject = {
  __typename?: 'MyReferrerOrganizationObject';
  moneyGathered: Scalars['Float'];
  organization: OrganizationObject;
};

export type MyReferrerOrganizationResponseObject = {
  __typename?: 'MyReferrerOrganizationResponseObject';
  error?: Maybe<MyReferrerOrganizationResponseObjectErrorObject>;
  object?: Maybe<MyReferrerOrganizationObject>;
};

export type MyReferrerOrganizationResponseObjectErrorObject = {
  __typename?: 'MyReferrerOrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NewNonprofitObject = {
  __typename?: 'NewNonprofitObject';
  city?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  einBn: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type NewNonprofitObjectL3gHKuT0 = {
  __typename?: 'NewNonprofitObjectL3gHKuT0';
  error?: Maybe<NewNonprofitObjectL3gHKuT0ErrorObject>;
  items?: Maybe<Array<NewNonprofitObject>>;
};

export type NewNonprofitObjectL3gHKuT0ErrorObject = {
  __typename?: 'NewNonprofitObjectL3gHKuT0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum NewSubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Created = 'Created',
  PastDue = 'PastDue'
}

export type NextActionObject = {
  __typename?: 'NextActionObject';
  paymentIntentSecret?: Maybe<Scalars['String']>;
  redirectToUrl?: Maybe<RedirectToUrlObject>;
  type?: Maybe<NextActionType>;
};

export enum NextActionType {
  OpenAchModal = 'OpenAchModal',
  OpenPadModal = 'OpenPadModal',
  ThreeDSecureRedirect = 'ThreeDSecureRedirect'
}

export type NoteObject = {
  __typename?: 'NoteObject';
  contactId: Scalars['String'];
  content: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  writer?: Maybe<UserObject>;
  writerId: Scalars['String'];
};

export type NoteResponseListObject = {
  __typename?: 'NoteResponseListObject';
  error?: Maybe<NoteResponseListObjectErrorObject>;
  items?: Maybe<Array<NoteObject>>;
};

export type NoteResponseListObjectErrorObject = {
  __typename?: 'NoteResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NoteResponseObject = {
  __typename?: 'NoteResponseObject';
  error?: Maybe<NoteResponseObjectErrorObject>;
  object?: Maybe<NoteObject>;
};

export type NoteResponseObjectErrorObject = {
  __typename?: 'NoteResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Number8a9pSxFf = {
  __typename?: 'Number8a9pSXFf';
  error?: Maybe<Number8a9pSxFfErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type Number8a9pSxFfErrorObject = {
  __typename?: 'Number8a9pSXFfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Number29Nker9k = {
  __typename?: 'Number29Nker9k';
  error?: Maybe<Number29Nker9kErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type Number29Nker9kErrorObject = {
  __typename?: 'Number29Nker9kErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberC180Sqb1 = {
  __typename?: 'NumberC180SQB1';
  error?: Maybe<NumberC180Sqb1ErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberC180Sqb1ErrorObject = {
  __typename?: 'NumberC180SQB1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObject = {
  __typename?: 'NumberResponseObject';
  error?: Maybe<NumberResponseObjectErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberResponseObjectErrorObject = {
  __typename?: 'NumberResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObjectIktmyeBa = {
  __typename?: 'NumberResponseObjectIKTMYEBa';
  error?: Maybe<NumberResponseObjectIktmyeBaErrorObject>;
  object?: Maybe<NumberResponseObject>;
};

export type NumberResponseObjectIktmyeBaErrorObject = {
  __typename?: 'NumberResponseObjectIKTMYEBaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberiFivpyb3 = {
  __typename?: 'NumberiFivpyb3';
  error?: Maybe<NumberiFivpyb3ErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberiFivpyb3ErrorObject = {
  __typename?: 'NumberiFivpyb3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberzaUvvHfT = {
  __typename?: 'NumberzaUVVHfT';
  error?: Maybe<NumberzaUvvHfTErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberzaUvvHfTErrorObject = {
  __typename?: 'NumberzaUVVHfTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesListResponseObjectErrorObject = {
  __typename?: 'OccurrenceWithRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesObject = {
  __typename?: 'OccurrenceWithRatesObject';
  occurrence?: Maybe<TicketingOccurrenceObject>;
  rates: Array<RateByOccurrenceObject>;
};

export enum OnboardingQualification {
  Large = 'Large',
  Medium = 'Medium',
  Other = 'Other',
  Small = 'Small'
}

export type OnboardingQualificationInput = {
  onboardingQualification: OnboardingQualification;
};

export type OrderObject = {
  __typename?: 'OrderObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  createdCampaigns?: Maybe<CreatedCampaignsObject>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<DiscountObject>;
  discountId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extraDonation?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastTransactionId?: Maybe<Scalars['String']>;
  locale: Locales;
  occurrence?: Maybe<TicketingOccurrenceObject>;
  occurrenceId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  ticketing: TicketingObject;
  ticketingId: Scalars['String'];
  tickets?: Maybe<Array<TicketObject>>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type OrderResponseObjectErrorObject = {
  __typename?: 'OrderResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationAndOwnerObject = {
  __typename?: 'OrganizationAndOwnerObject';
  email: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  role: Scalars['String'];
  userId: Scalars['String'];
};

export type OrganizationAndOwnerObjectpfGzGybN = {
  __typename?: 'OrganizationAndOwnerObjectpfGzGybN';
  error?: Maybe<OrganizationAndOwnerObjectpfGzGybNErrorObject>;
  items?: Maybe<Array<OrganizationAndOwnerObject>>;
};

export type OrganizationAndOwnerObjectpfGzGybNErrorObject = {
  __typename?: 'OrganizationAndOwnerObjectpfGzGybNErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationCategory {
  AnimalWelfare = 'AnimalWelfare',
  CommunityService = 'CommunityService',
  Culture = 'Culture',
  Education = 'Education',
  Environment = 'Environment',
  Health = 'Health',
  Humanitarian = 'Humanitarian',
  Other = 'Other',
  Politic = 'Politic',
  Religion = 'Religion',
  SocialService = 'SocialService',
  SportsAndLeisure = 'SportsAndLeisure',
  StudentGroup = 'StudentGroup',
  Veterans = 'Veterans'
}

export enum OrganizationCause {
  Education = 'Education',
  Nature = 'Nature',
  Solidarity = 'Solidarity',
  SportAndCulture = 'SportAndCulture'
}

export enum OrganizationCountry {
  Canada = 'Canada',
  GreatBritain = 'GreatBritain',
  UnitedStates = 'UnitedStates'
}

export type OrganizationDetailsObject = {
  __typename?: 'OrganizationDetailsObject';
  closingDate?: Maybe<Scalars['Date']>;
  fundsRaised: Scalars['Float'];
  numberContacts: Scalars['Float'];
  numberForms: Scalars['Float'];
  numberOfReferrals: Scalars['Float'];
  numberPayments: Scalars['Float'];
  numberUserAccounts: Scalars['Float'];
};

export type OrganizationEngagementFlagsObject = {
  __typename?: 'OrganizationEngagementFlagsObject';
  engagementAddContactTour: Scalars['Boolean'];
  engagementContactNotesTour: Scalars['Boolean'];
  engagementContactPaymentsTour: Scalars['Boolean'];
  engagementContactTagsTour: Scalars['Boolean'];
  engagementContactsBulkTour: Scalars['Boolean'];
  engagementEmailRecipientsTour: Scalars['Boolean'];
  engagementEmailStatsTour: Scalars['Boolean'];
  engagementEmailTemplatesTour: Scalars['Boolean'];
  engagementPaymentDetailsTour: Scalars['Boolean'];
  engagementPaymentTour: Scalars['Boolean'];
  engagementPaymentsExportTour: Scalars['Boolean'];
  id: Scalars['String'];
};

export type OrganizationEngagementFlagsObjectwk0Sfr7i = {
  __typename?: 'OrganizationEngagementFlagsObjectwk0SFR7i';
  error?: Maybe<OrganizationEngagementFlagsObjectwk0Sfr7iErrorObject>;
  object?: Maybe<OrganizationEngagementFlagsObject>;
};

export type OrganizationEngagementFlagsObjectwk0Sfr7iErrorObject = {
  __typename?: 'OrganizationEngagementFlagsObjectwk0SFR7iErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationFlagsInput = {
  demotivateEmbeds?: InputMaybe<AmplitudeVariantValue>;
};

export type OrganizationGroupObject = {
  __typename?: 'OrganizationGroupObject';
  id: Scalars['String'];
  organization: OrganizationObject;
  organizationId: Scalars['String'];
  permissions: Array<UserPermissions>;
  role: UserRoles;
  user: UserObject;
};

export type OrganizationObject = {
  __typename?: 'OrganizationObject';
  activationChecklistExperiment?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  badTipperHubspotFlag?: Maybe<Scalars['Boolean']>;
  balanceTransferDelayDay: Scalars['Float'];
  bankDescriptor?: Maybe<Scalars['String']>;
  blockPayment: Scalars['Boolean'];
  canGenerateReceipt: Scalars['Boolean'];
  cardMaximumAmount?: Maybe<Scalars['Int']>;
  category?: Maybe<OrganizationCategory>;
  cause?: Maybe<OrganizationCause>;
  charityNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  createdAtUtc?: Maybe<Scalars['Date']>;
  demotivateEmbeds?: Maybe<AmplitudeVariantValue>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  dismissOnboardingOnFormPageAt?: Maybe<Scalars['Date']>;
  engagementCheckListStepChecked?: Maybe<Array<Scalars['String']>>;
  engagementChecklistExperiment?: Maybe<AmplitudeVariantValue>;
  featureIssuingEnabled: Scalars['Boolean'];
  featureTreasuryEnabled: Scalars['Boolean'];
  firstPaymentDate?: Maybe<Scalars['Date']>;
  fourthPaymentDate?: Maybe<Scalars['Date']>;
  fraudulent: Scalars['Boolean'];
  fraudulentAtUtc?: Maybe<Scalars['Date']>;
  hasAdministrativeInformation: Scalars['Boolean'];
  hasAtLeastOnePayment?: Maybe<Scalars['Boolean']>;
  hasCharityNumber: Scalars['Boolean'];
  hasDismissActivationPayoutSuccessCard?: Maybe<Scalars['Boolean']>;
  hasFinishZeffyOnboarding: Scalars['Boolean'];
  hasImportGmailContacts?: Maybe<Scalars['Boolean']>;
  hasImportMailchimpContacts?: Maybe<Scalars['Boolean']>;
  hasStripeCustomFlowStarted: Scalars['Boolean'];
  hasVisitorReports?: Maybe<Scalars['Boolean']>;
  hideFromZeffyMap: Scalars['Boolean'];
  hubspotNpoStarterDealId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOnboarded?: Maybe<Scalars['Boolean']>;
  isPaymentDisabled?: Maybe<Scalars['Boolean']>;
  isPoBoxAddress?: Maybe<Scalars['Boolean']>;
  isStripeAccountDeprecated?: Maybe<Scalars['Boolean']>;
  isStripeCustomAccountActive: Scalars['Boolean'];
  lastPaymentDate?: Maybe<Scalars['Date']>;
  legalName?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  npoStarterStateCertificateUrl?: Maybe<Scalars['String']>;
  npoStarterToDoListStepChecked?: Maybe<Array<Scalars['String']>>;
  onboardedFormCategory?: Maybe<FormCategory>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  owner?: Maybe<UserObject>;
  ownerEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  previewFirstFormAt?: Maybe<Scalars['Date']>;
  propublicaCCode?: Maybe<Scalars['Float']>;
  referralAmountLimit: Scalars['Float'];
  referralQuestion?: Maybe<ReferralQuestionChoice>;
  referralQuestionOther?: Maybe<Scalars['String']>;
  referredByOrganizationId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  stripeCustomAccountActiveAtUtc?: Maybe<Scalars['Date']>;
  stripeCustomAccountId?: Maybe<Scalars['String']>;
  stripeTopupSourceId?: Maybe<Scalars['String']>;
  stripeUserId?: Maybe<Scalars['String']>;
  stripeVerificationSucceededBannerShown?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<TagObject>>;
  type?: Maybe<OrganizationType>;
  volumeThresholdReached?: Maybe<Scalars['Float']>;
  website?: Maybe<Scalars['String']>;
};

export type OrganizationObject0rCgaq3w = {
  __typename?: 'OrganizationObject0rCGAQ3w';
  error?: Maybe<OrganizationObject0rCgaq3wErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObject0rCgaq3wErrorObject = {
  __typename?: 'OrganizationObject0rCGAQ3wErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationObjectJuu6iIdU = {
  __typename?: 'OrganizationObjectJUU6iIdU';
  error?: Maybe<OrganizationObjectJuu6iIdUErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjectJuu6iIdUErrorObject = {
  __typename?: 'OrganizationObjectJUU6iIdUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicObject = {
  __typename?: 'OrganizationPublicObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<OwnerObject>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type OrganizationPublicResponseListObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicResponseObject = {
  __typename?: 'OrganizationPublicResponseObject';
  error?: Maybe<OrganizationPublicResponseObjectErrorObject>;
  object?: Maybe<OrganizationPublicObject>;
};

export type OrganizationPublicResponseObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseListObjectErrorObject = {
  __typename?: 'OrganizationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseObject = {
  __typename?: 'OrganizationResponseObject';
  error?: Maybe<OrganizationResponseObjectErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationResponseObjectErrorObject = {
  __typename?: 'OrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationType {
  Charity = 'Charity',
  Other = 'Other',
  Other501C = 'Other501C',
  Private = 'Private',
  Social = 'Social'
}

export type OverTimeFilterInput = {
  slot: OverTimeSlot;
};

export enum OverTimeSlot {
  fourteenDays = 'fourteenDays',
  oneMonth = 'oneMonth',
  sevenDays = 'sevenDays',
  seventyTwoHours = 'seventyTwoHours',
  twentyFourHours = 'twentyFourHours',
  twoHours = 'twoHours'
}

export type OwnerObject = {
  __typename?: 'OwnerObject';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type PageViewsSummaryItemObject = {
  __typename?: 'PageViewsSummaryItemObject';
  count: Scalars['Float'];
  time: Scalars['String'];
};

export type PageViewsSummaryObject = {
  __typename?: 'PageViewsSummaryObject';
  data?: Maybe<Array<PageViewsSummaryItemObject>>;
};

export type PageViewsSummaryResponseObject = {
  __typename?: 'PageViewsSummaryResponseObject';
  error?: Maybe<PageViewsSummaryResponseObjectErrorObject>;
  object?: Maybe<PageViewsSummaryObject>;
};

export type PageViewsSummaryResponseObjectErrorObject = {
  __typename?: 'PageViewsSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentIntentError {
  CardDeclined = 'CardDeclined',
  FormNotFound = 'FormNotFound',
  InsufficientCapabilitiesForTransfer = 'InsufficientCapabilitiesForTransfer',
  InsufficientFunds = 'InsufficientFunds',
  OwnerNotFound = 'OwnerNotFound',
  UnknownError = 'UnknownError'
}

export type PaymentIntentObject = {
  __typename?: 'PaymentIntentObject';
  clientSecret: Scalars['String'];
  paymentIntentId: Scalars['String'];
};

export type PaymentIntentResponseObject = {
  __typename?: 'PaymentIntentResponseObject';
  error?: Maybe<PaymentIntentResponseObjectErrorObject>;
  object?: Maybe<PaymentIntentObject>;
};

export type PaymentIntentResponseObjectErrorObject = {
  __typename?: 'PaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentMethod {
  Ach = 'Ach',
  ApplePayOrGooglePay = 'ApplePayOrGooglePay',
  Card = 'Card',
  Cash = 'Cash',
  CashApp = 'CashApp',
  Cheque = 'Cheque',
  Free = 'Free',
  InKind = 'InKind',
  Manual = 'Manual',
  Other = 'Other',
  Pad = 'Pad',
  Stocks = 'Stocks',
  TapToPay = 'TapToPay',
  Transfer = 'Transfer',
  Unknown = 'Unknown'
}

export enum PaymentStatus {
  AwaitingTransfer = 'AwaitingTransfer',
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum PayoutInterval {
  Daily = 'Daily',
  Manual = 'Manual',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type PayoutObject = {
  __typename?: 'PayoutObject';
  amount: Scalars['Float'];
  arrivalDate: Scalars['Date'];
  createdAt: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  externalAccount: ExternalAccountObject;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
};

export type PayoutResponseObject = {
  __typename?: 'PayoutResponseObject';
  error?: Maybe<PayoutResponseObjectErrorObject>;
  object?: Maybe<PayoutObject>;
};

export type PayoutResponseObjectErrorObject = {
  __typename?: 'PayoutResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PayoutsInfiniteListResponseObjectErrorObject = {
  __typename?: 'PayoutsInfiniteListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PostSubmitCommandObject = {
  __typename?: 'PostSubmitCommandObject';
  createFundraisingHandler?: Maybe<CreatedCampaignsObject>;
};

export type PostSubmitCommandObjectZpkqymmk = {
  __typename?: 'PostSubmitCommandObjectZPKQYMMK';
  error?: Maybe<PostSubmitCommandObjectZpkqymmkErrorObject>;
  object?: Maybe<PostSubmitCommandObject>;
};

export type PostSubmitCommandObjectZpkqymmkErrorObject = {
  __typename?: 'PostSubmitCommandObjectZPKQYMMKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PreviewReceiptParams = {
  amount?: InputMaybe<Scalars['Float']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  formType: FormType;
  locale?: InputMaybe<Scalars['String']>;
  rateName?: InputMaybe<Scalars['String']>;
  ticketingName?: InputMaybe<Scalars['String']>;
};

export type PreviewTicketParams = {
  address?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  endUtc?: InputMaybe<Scalars['Date']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  formTitle?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  rateTitle?: InputMaybe<Scalars['String']>;
  startUtc?: InputMaybe<Scalars['Date']>;
  ticketingColor?: InputMaybe<Scalars['String']>;
};

export type ProcessBalanceTransferInput = {
  offsetDay: Scalars['Float'];
};

export type ProductBidObject = {
  __typename?: 'ProductBidObject';
  amount: Scalars['Int'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  rate?: Maybe<RateObject>;
  rateId: Scalars['String'];
  status: ProductBidStatus;
};

export type ProductBidObject6QkDhwgL = {
  __typename?: 'ProductBidObject6QkDhwgL';
  error?: Maybe<ProductBidObject6QkDhwgLErrorObject>;
  items?: Maybe<Array<ProductBidObject>>;
};

export type ProductBidObject6QkDhwgLErrorObject = {
  __typename?: 'ProductBidObject6QkDhwgLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductBidStatus {
  Lost = 'Lost',
  Submitted = 'Submitted',
  Won = 'Won'
}

export type ProductDonationObject = {
  __typename?: 'ProductDonationObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  id: Scalars['String'];
  inHonourEmailAddress?: Maybe<Scalars['String']>;
  inHonourEmailBody?: Maybe<Scalars['String']>;
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  isRecurrent: Scalars['Boolean'];
  organizationId: Scalars['String'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: Maybe<Scalars['String']>;
};

export enum ProductDonationRecurrenceInterval {
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Yearly = 'Yearly'
}

export type ProductDonationResponseObjectErrorObject = {
  __typename?: 'ProductDonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketCountsObject = {
  __typename?: 'ProductTicketCountsObject';
  id: Scalars['String'];
  productTicketCheckedIn: Scalars['Int'];
  productTicketTotal: Scalars['Int'];
};

export type ProductTicketCountsObjectDaZaFeOd = {
  __typename?: 'ProductTicketCountsObjectDaZaFeOD';
  error?: Maybe<ProductTicketCountsObjectDaZaFeOdErrorObject>;
  object?: Maybe<ProductTicketCountsObject>;
};

export type ProductTicketCountsObjectDaZaFeOdErrorObject = {
  __typename?: 'ProductTicketCountsObjectDaZaFeODErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketListResponseObject = {
  __typename?: 'ProductTicketListResponseObject';
  error?: Maybe<ProductTicketListResponseObjectErrorObject>;
  items?: Maybe<Array<ProductTicketObject>>;
};

export type ProductTicketListResponseObjectErrorObject = {
  __typename?: 'ProductTicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketObject = {
  __typename?: 'ProductTicketObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal?: Maybe<Scalars['Boolean']>;
  command?: Maybe<CommandObject>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  expirationDate?: Maybe<Scalars['Date']>;
  groupTicketId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organizationId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  status: ProductTicketStatus;
  ticketingIndex?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type ProductTicketObjectSi17z4j2 = {
  __typename?: 'ProductTicketObjectSI17z4j2';
  error?: Maybe<ProductTicketObjectSi17z4j2ErrorObject>;
  object?: Maybe<ProductTicketObject>;
};

export type ProductTicketObjectSi17z4j2ErrorObject = {
  __typename?: 'ProductTicketObjectSI17z4j2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketResponseObjectErrorObject = {
  __typename?: 'ProductTicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductTicketStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  CheckedIn = 'CheckedIn',
  Pending = 'Pending'
}

export type ProductTicketStatusCountPerTicketingPerOccurrenceObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObject';
  cancelledCount: Scalars['String'];
  checkedInCount: Scalars['String'];
  filteredRowsEmails: Array<Scalars['String']>;
  notCheckedInCount: Scalars['String'];
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObjectPSaaImda = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObjectPSaaIMDA';
  error?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObjectPSaaImdaErrorObject>;
  object?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObject>;
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObjectPSaaImdaErrorObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObjectPSaaIMDAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkIfResetPasswordIsValid: Booleanv9jhrDmm;
  defaultQuery: Scalars['Boolean'];
  doesOrganizationHaveProductBids: BooleanPCxdp4Tt;
  donationForm: DonationFormObjectL9imsWeq;
  donationFormWithRestrictedAccess: DonationFormObject9Evp7ehe;
  fetchAndSetSkipAIChat: Boolean7A0iTcGs;
  generateCheckinManagerUrl: String4DzmvyLe;
  getActiveTicketingOccurrenceIds: StringH2lZ99bc;
  getAggregateProductTicketStatusCountPerTicketingPerOccurrence: ProductTicketStatusCountPerTicketingPerOccurrenceObjectPSaaImda;
  getAggregateRateFieldsByCommand: AggregateRateFieldsObjectKsSdD7gZ;
  getApplicationInitialStatus: ApplicationInitialStatusResponseObject;
  getBalanceTransactionDetails: GetBalanceTransactionDetailsObjectgap1cn4w;
  getCampaignFormsCount: NumberzaUvvHfT;
  getCampaignParticipantContacts: ContactObjectaDhLvWqd;
  getCampaignSubFormsCount: NumberResponseObject;
  getCampaignWithVolume?: Maybe<DonationFormResponseObject>;
  getCardVolume: CardVolumeResponseObject;
  getCommandAnswers: AnswerObjectYxaWt5rq;
  getContactCountByTag: NumberResponseObject;
  getContactDetails: ContactObjectpfWtIdjw;
  getContactKeyInfo: ContactKeyInfoObjectEWmiN8rr;
  getContactsFormRecipientIds: ContactObjectnyOtYk6t;
  getCurrentUser?: Maybe<GetCurrentUserObject>;
  getCurrentlyActiveOrganizations: CurrentlyActiveOrganizationResponseListObject;
  getDiscount: DiscountResponseObject;
  getDonationFormForManualDonation: DonationFormObjectEc4d53Ms;
  getDonationFormQuestions: QuestionListResponseObject;
  getDonationFormWithFundraiserAccess: DonationFormObjecthjdaXttd;
  getDonationFormsFromCampaign: GetDonationFormsFromCampaignObjectGam0oBie;
  getDonationFormsList: DonationFormObjectwAKg883n;
  getDonorVolume: DonorVolumeListResponseObject;
  getEmailDelivery: EmailDeliveryObjectXt3nnwzS;
  getEmailTemplates: EmailTemplateResponseListObject;
  getEmailsCountByStatus: EmailsCountByStatusTableListResponseObject;
  getEphemeralKey: GetEphemeralKeyObject01d3nVcb;
  getFirstTransactionCreatedAtUtc: GetFirstTransactionCreatedAtUtcResponseObjectpnvEkxWj;
  getFormVolume: NumberiFivpyb3;
  getFrontendCurrentUser: GetCurrentUserFrontendObject3ImqOVzp;
  getFundraisingOrganization: OrganizationResponseObject;
  getHomeGraphData: HomeGraphResponseListObject;
  getImportStatus: ImportUsersGoogleSheetObjectOMymSkMf;
  getIsActivatedFeatureToggleBadTipperDisclaimer: BooleanTy8g0vpB;
  getIsNewMobileFormCreationFlow: BooleankVit2hBp;
  getIssuingCard: GetIssuingCardObjectCu2qJyxj;
  getIssuingRewardTransactions: GetIssuingRewardTransactionsObjectTbwp6kAs;
  getMarkerHoverDetails: MarkerHoverDetailsObject;
  getMarkerPositions: ClusterResponseObject;
  getMyReferralOrganizations: MyReferralOrganizationObjectmUiG4F5H;
  getMyReferrerInformation: MyReferrerOrganizationResponseObject;
  getNextReferralTransferAmount: NumberResponseObject;
  getNotes: NoteResponseListObject;
  getOrganizationAdmin: UserObjectpVfCvkNk;
  getOrganizationEngagementFlags: OrganizationEngagementFlagsObjectwk0Sfr7i;
  getOrganizationForms: FrontendFormObjectResponseListObject;
  getOrganizationHasAnyTag: NumberResponseObject;
  getOrganizationListByInput: OrganizationAndOwnerObjectpfGzGybN;
  getOrganizationMembers: UserListResponseObject;
  getOrganizationOwner: UserResponseObject;
  getOrganizationSummary: GetOrganizationSummaryResponseObject;
  getOrganizationTags: TagListResponseObject;
  getPageViewsSummary: PageViewsSummaryResponseObject;
  getPayout: PayoutResponseObject;
  getProductTicketCountsForCheckin: ProductTicketCountsObjectDaZaFeOd;
  getProductTicketsCount: NumberResponseObjectIktmyeBa;
  getReferredOrganization: OrganizationResponseObject;
  getScheduledPayout: GetScheduledPayoutObject3ZpGgN3O;
  getSentryIssues: SentryIssueListResponseObject;
  getStripeAccountBalance: AccountBalancesObjectUfVfnaNs;
  getStripeAccountLink: StringKliV4gpX;
  getStripeCustomAccount: StripeAccountResponseObject;
  getTags: TagListResponseObject;
  getTagsByContactIds: ContactIdWithTagsObjectejpgTUkw;
  getTeamMembers: DonationFormObject28lTnNyY;
  getTeamWithVolume: DonationFormObjectYRs1hask;
  getTeamsFromCampaignId: Array<DonationFormObject>;
  getTicketing: TicketingObjectwvQKg0bl;
  getTicketingCategory: TicketingCategoryObjecti5THpDdP;
  getTicketingForManualOrder: TicketingObject4izy9bNk;
  getTicketingInfoForCheckin: TicketingObjectfrcB8jxi;
  getTicketingList: TicketingObject4wHVfuGm;
  getTicketingReminderData: TicketingFieldObjectgy1E3lFl;
  getTipAndFeeAndRefundTipVolumeFromTransaction: TipNetVolumeResponseObject;
  getTipSuggestions: Array<TipSuggestionObject>;
  getTransaction: TransactionResponseObject;
  getTransactionCountOverTime: TransactionsOverTimeListResponseObject;
  getTransactionWithCommand: TransactionResponseObject;
  getTransactionsForIssuingBalanceTransfer: GetTransactionsForIssuingBalanceTransferObject1JMcfLnW;
  getUnsubscribePreferences: UnsubscribePreferencesObjectpXnxuc5B;
  getVolumeAtDate: NumberResponseObject;
  getWebAnalytics: WebAnalyticsResponseObject;
  getWebhooksCountOverTime: WebhooksOverTimeListResponseObject;
  hasAnOccurrenceBefore: Booleanjtadtopw;
  hasAnyReceipt: BooleanJp0DvTx7;
  hasContacts: BooleanResponseObject;
  hasEmailDelivery: HasEmailDeliveryObjectB29Dd8jp;
  isAnExistingUser: BooleanrLmok7Kx;
  listCampaignFormTitles: FormTitleListResponse;
  listCommandsFromByOccurrenceId: CommandObjectvCcecFe2;
  listDashboardCommandBidRates: BidRateObjectQtSiO7Rv;
  listDashboardCommandForms: DashboardCommandFormListResponse;
  listDashboardCommandRates: DashboardCommandRatesListResponseObject;
  listDashboardContactTransactionsCount: DashboardCountObjectmh17peyA;
  listDashboardTransactionsRates: TransactionRatesWithQuantityObject29Ut8uS5;
  listDonorDashboardCommandForms: DashboardCommandFormListResponse;
  listDonorDashboardTransactionsCount: IntResponseObject;
  listExpiringTransactionTickets: ProductTicketListResponseObject;
  listFormsForManualCommand: FrontendFormObjectmnNgnkt0;
  listOrganizationFormTitles: FormTitleListResponse;
  listQuestionsWithChoices: QuestionListResponseObject;
  listTransactionBids: ProductBidObject6QkDhwgL;
  listTransactionTickets: ListTransactionTicketsResponse;
  listTransactionsWithFullyCanceledTickets: StringListResponseObject;
  organizationFormsAsMultiselectValues: MultiselectValueObjectZdTnhvey;
  organizationOccurrencesAsMultiselectValues: MultiselectOccurrenceValueObjectk5uuOmRw;
  organizationTagsAsMultiselectValues: MultiselectValueResponseListObject;
  retrieveCommand: CommandObjectszfqffkS;
  retrieveCommandAnswers: AnswerResponseListObject;
  retrieveContact: ContactResponseObject;
  retrieveDashboardTransaction: DashboardTransactionObjectH9sUUdiV;
  retrieveDonorCommand: CommandObjectGmQkhkzP;
  retrieveDonorDashboardSubscription: CommandSubscriptionResponseObject;
  retrieveDonorDashboardTransaction: DashboardTransactionObjectY8qy6Mqd;
  retrieveDonorReceipt: ReceiptResponseObject;
  retrieveForm: FrontendFormObjectResponseObject;
  retrieveOrganization: OrganizationPublicResponseObject;
  retrieveProductTicketAnswers: AnswerObject54gZg9Bm;
  retrieveProductTicketAnswersForCheckin: AnswerObjectPTnoDhpW;
  retrieveReceipt: ReceiptResponseObject;
  retrieveTransactionReceipts: ReceiptObjectyBvqPqyc;
  retrieveUnsubscribeContactInformations: ContactResponseObject;
  searchNonprofit: NewNonprofitObjectL3gHKuT0;
  searchUnsplashImages: SearchUnsplashImagesResponseObject;
  shouldDisplayPostOccurrenceThankYouEmail: Boolean9No4gStq;
  ticketingRatesAsMultiselectValues: MultiselectValueObjects32uVrkW;
};


export type QueryCheckIfResetPasswordIsValidArgs = {
  checkIfResetPasswordIsValidInput: CheckIfResetPasswordIsValidInput;
};


export type QueryDonationFormArgs = {
  donationFormIdOrUrlPath: Scalars['String'];
};


export type QueryDonationFormWithRestrictedAccessArgs = {
  donationFormIdInput: UuidInput;
};


export type QueryGenerateCheckinManagerUrlArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetActiveTicketingOccurrenceIdsArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateProductTicketStatusCountPerTicketingPerOccurrenceArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateRateFieldsByCommandArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetBalanceTransactionDetailsArgs = {
  balanceTransactionId: Scalars['String'];
  type: BalanceTransactionType;
};


export type QueryGetCampaignFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignParticipantContactsArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignSubFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignWithVolumeArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCardVolumeArgs = {
  parameters?: InputMaybe<HomeMetricsInput>;
};


export type QueryGetCommandAnswersArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetContactCountByTagArgs = {
  tagId: Scalars['String'];
};


export type QueryGetContactDetailsArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactKeyInfoArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactsFormRecipientIdsArgs = {
  recipientIds: Array<Scalars['String']>;
};


export type QueryGetCurrentlyActiveOrganizationsArgs = {
  input?: InputMaybe<Scalars['String']>;
};


export type QueryGetDiscountArgs = {
  id: Scalars['String'];
};


export type QueryGetDonationFormForManualDonationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormQuestionsArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormWithFundraiserAccessArgs = {
  donationFormIdInput: UuidInput;
  token: Scalars['String'];
};


export type QueryGetDonationFormsFromCampaignArgs = {
  campaignId: Scalars['String'];
  filters: DonationFormCampaignFilterInput;
};


export type QueryGetDonationFormsListArgs = {
  withArchived: Scalars['Boolean'];
};


export type QueryGetEmailDeliveryArgs = {
  emailDeliveryIdInput: UuidInput;
};


export type QueryGetEmailsCountByStatusArgs = {
  emailDeliveryId: Scalars['String'];
};


export type QueryGetEphemeralKeyArgs = {
  input: GetEphemeralKeyInput;
};


export type QueryGetFormVolumeArgs = {
  formInput: FormInput;
};


export type QueryGetFundraisingOrganizationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetHomeGraphDataArgs = {
  parameters: HomeGraphInput;
};


export type QueryGetImportStatusArgs = {
  importId: Scalars['String'];
};


export type QueryGetMarkerHoverDetailsArgs = {
  getMarkerHoverDetailsInput: GetMarkerHoverDetailsInput;
};


export type QueryGetMarkerPositionsArgs = {
  getMarkerInput: GetMarkersInput;
};


export type QueryGetNotesArgs = {
  userId: Scalars['String'];
};


export type QueryGetOrganizationAdminArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationFormsArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationListByInputArgs = {
  searchOragnizationInput: Scalars['String'];
};


export type QueryGetOrganizationOwnerArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationSummaryArgs = {
  locale: Locales;
  organizationId: Scalars['String'];
};


export type QueryGetPageViewsSummaryArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetPayoutArgs = {
  id: Scalars['String'];
};


export type QueryGetProductTicketCountsForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetProductTicketsCountArgs = {
  commandId: Scalars['String'];
};


export type QueryGetReferredOrganizationArgs = {
  params: GetReferredOrganizationInput;
};


export type QueryGetSentryIssuesArgs = {
  slot: SentrySlot;
};


export type QueryGetTagsArgs = {
  userId: Scalars['String'];
};


export type QueryGetTagsByContactIdsArgs = {
  contactIds: Array<Scalars['String']>;
};


export type QueryGetTeamMembersArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamWithVolumeArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamsFromCampaignIdArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingCategoryArgs = {
  params: UuidInput;
};


export type QueryGetTicketingForManualOrderArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingInfoForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetTicketingReminderDataArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTipSuggestionsArgs = {
  getTipSuggestionsInput: GetTipSuggestionsInput;
};


export type QueryGetTransactionArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetTransactionWithCommandArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionsForIssuingBalanceTransferArgs = {
  input: ProcessBalanceTransferInput;
};


export type QueryGetVolumeAtDateArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type QueryGetWebAnalyticsArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetWebhooksCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryHasAnOccurrenceBeforeArgs = {
  days: Scalars['Int'];
  ticketingId: Scalars['String'];
};


export type QueryIsAnExistingUserArgs = {
  email: Scalars['String'];
};


export type QueryListCampaignFormTitlesArgs = {
  campaignId: Scalars['String'];
};


export type QueryListCommandsFromByOccurrenceIdArgs = {
  occurrenceId: Scalars['String'];
};


export type QueryListDashboardCommandBidRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListDashboardCommandRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardContactTransactionsCountArgs = {
  userId: Scalars['String'];
};


export type QueryListDashboardTransactionsRatesArgs = {
  locale: Locales;
  transactionIds: Array<Scalars['String']>;
};


export type QueryListDonorDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListExpiringTransactionTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryListQuestionsWithChoicesArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  questionIds: Array<Scalars['String']>;
};


export type QueryListTransactionBidsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionTicketsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionsWithFullyCanceledTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryOrganizationOccurrencesAsMultiselectValuesArgs = {
  formIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryRetrieveCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveCommandAnswersArgs = {
  commandId: Scalars['String'];
};


export type QueryRetrieveContactArgs = {
  contactId: Scalars['String'];
};


export type QueryRetrieveDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveFormArgs = {
  formInput: FormInput;
};


export type QueryRetrieveOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveProductTicketAnswersArgs = {
  productTicketIds: Array<Scalars['String']>;
};


export type QueryRetrieveProductTicketAnswersForCheckinArgs = {
  productTicketIds: Array<Scalars['String']>;
  token: Scalars['String'];
};


export type QueryRetrieveReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveTransactionReceiptsArgs = {
  transactionId: Scalars['String'];
};


export type QueryRetrieveUnsubscribeContactInformationsArgs = {
  contactId: Scalars['String'];
};


export type QuerySearchNonprofitArgs = {
  filters: SearchNonprofitFilterInput;
  query: Scalars['String'];
};


export type QuerySearchUnsplashImagesArgs = {
  searchUnsplashImagesInput: SearchUnsplashImagesInput;
};


export type QueryShouldDisplayPostOccurrenceThankYouEmailArgs = {
  occurrenceIdInput: UuidInput;
};


export type QueryTicketingRatesAsMultiselectValuesArgs = {
  formId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldInput = {
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  questionId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldObject = {
  __typename?: 'QuestionFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type QuestionInput = {
  choices: Array<ChoiceInput>;
  donationFormId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  questionFields: Array<QuestionFieldInput>;
  ratesAssigned?: InputMaybe<Array<Scalars['String']>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  ticketingId?: InputMaybe<Scalars['String']>;
  type: QuestionType;
};

export type QuestionListResponseObject = {
  __typename?: 'QuestionListResponseObject';
  error?: Maybe<QuestionListResponseObjectErrorObject>;
  items?: Maybe<Array<QuestionObject>>;
};

export type QuestionListResponseObjectErrorObject = {
  __typename?: 'QuestionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type QuestionObject = {
  __typename?: 'QuestionObject';
  answers?: Maybe<Array<AnswerObject>>;
  choices: Array<ChoiceObject>;
  countAlreadyAnswered: Scalars['Float'];
  hasRate: Scalars['Boolean'];
  id: Scalars['String'];
  questionFields: Array<QuestionFieldObject>;
  rateQuestions?: Maybe<Array<RateQuestionObject>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  type: QuestionType;
};

export enum QuestionType {
  Checkbox = 'Checkbox',
  Date = 'Date',
  Email = 'Email',
  Multiple = 'Multiple',
  MultipleCheckboxes = 'MultipleCheckboxes',
  Name = 'Name',
  PhoneNumber = 'PhoneNumber',
  Simple = 'Simple',
  Utm = 'Utm'
}

export type RateByOccurrenceObject = {
  __typename?: 'RateByOccurrenceObject';
  rateId: Scalars['String'];
  remainingTickets?: Maybe<Scalars['Float']>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
};

export type RateFieldObject = {
  __typename?: 'RateFieldObject';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type RateFieldObjectWithCount = {
  __typename?: 'RateFieldObjectWithCount';
  attendeesByTicket?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  locale: Locales;
  rateFieldCount: Scalars['Float'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type RateListResponseObjectErrorObject = {
  __typename?: 'RateListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RateObject = {
  __typename?: 'RateObject';
  allowAutomaticRenewal?: Maybe<Scalars['Boolean']>;
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  attendeesByTicket?: Maybe<Scalars['Float']>;
  bidIncrement?: Maybe<Scalars['Float']>;
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isRateFull?: Maybe<Scalars['Boolean']>;
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: Maybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: Maybe<Scalars['Date']>;
  minimumToBuy?: Maybe<Scalars['Float']>;
  photoUrls?: Maybe<Array<Scalars['String']>>;
  rateFields?: Maybe<Array<RateFieldObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  tickets?: Maybe<Array<TicketObject>>;
};

export type RateQuestionObject = {
  __typename?: 'RateQuestionObject';
  id: Scalars['String'];
  rateId: Scalars['String'];
};

export type RatesWithQuantityObject = {
  __typename?: 'RatesWithQuantityObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type ReceiptListResponseObjectErrorObject = {
  __typename?: 'ReceiptListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObject = {
  __typename?: 'ReceiptObject';
  address?: Maybe<Scalars['String']>;
  advantageAmount?: Maybe<Scalars['Float']>;
  advantageDescription?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  charityNumber: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationDate: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  isSample: Scalars['Boolean'];
  issuingAtUtc: Scalars['Date'];
  lastName?: Maybe<Scalars['String']>;
  organizationAddress: Scalars['String'];
  organizationCity: Scalars['String'];
  organizationCountry: OrganizationCountry;
  organizationName: Scalars['String'];
  organizationPostalCode: Scalars['String'];
  organizationRegion: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  receiptNumber: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  status: ReceiptStatus;
  transactions?: Maybe<Array<TransactionObject>>;
  userId?: Maybe<Scalars['String']>;
};

export type ReceiptObjectcOx0gKpb = {
  __typename?: 'ReceiptObjectcOX0gKpb';
  error?: Maybe<ReceiptObjectcOx0gKpbErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectcOx0gKpbErrorObject = {
  __typename?: 'ReceiptObjectcOX0gKpbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObjectyBvqPqyc = {
  __typename?: 'ReceiptObjectyBvqPqyc';
  error?: Maybe<ReceiptObjectyBvqPqycErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectyBvqPqycErrorObject = {
  __typename?: 'ReceiptObjectyBvqPqycErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptResponseObject = {
  __typename?: 'ReceiptResponseObject';
  error?: Maybe<ReceiptResponseObjectErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptResponseObjectErrorObject = {
  __typename?: 'ReceiptResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ReceiptStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Pending = 'Pending'
}

export type ReceiptTableListResponseObjectErrorObject = {
  __typename?: 'ReceiptTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RecipientObject = {
  __typename?: 'RecipientObject';
  emailAddress: Scalars['String'];
  id: Scalars['String'];
};

export type RedirectToUrlObject = {
  __typename?: 'RedirectToUrlObject';
  returnUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum ReferralQuestionChoice {
  AsDonor = 'AsDonor',
  Capterra = 'Capterra',
  Nbctc = 'Nbctc',
  OrganicSearch = 'OrganicSearch',
  Other = 'Other',
  ReferralFromDonor = 'ReferralFromDonor',
  ReferralFromOrganization = 'ReferralFromOrganization',
  SocialNetwork = 'SocialNetwork'
}

export type RefundObject = {
  __typename?: 'RefundObject';
  id: Scalars['String'];
};

export type RefundResponseObject = {
  __typename?: 'RefundResponseObject';
  error?: Maybe<RefundResponseObjectErrorObject>;
  object?: Maybe<RefundObject>;
};

export type RefundResponseObjectErrorObject = {
  __typename?: 'RefundResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};

export enum ResetPasswordLinkError {
  EmailDoesNotExist = 'EmailDoesNotExist'
}

export type ResetPasswordLinkObject = {
  __typename?: 'ResetPasswordLinkObject';
  user?: Maybe<UserObject>;
};

export type ResetPasswordLinkResponseObject = {
  __typename?: 'ResetPasswordLinkResponseObject';
  error?: Maybe<ResetPasswordLinkResponseObjectErrorObject>;
  object?: Maybe<ResetPasswordLinkObject>;
};

export type ResetPasswordLinkResponseObjectErrorObject = {
  __typename?: 'ResetPasswordLinkResponseObjectErrorObject';
  code?: Maybe<ResetPasswordLinkError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RetroActiveReferralInput = {
  orgReferredEmail: Scalars['String'];
  orgReferringEmail: Scalars['String'];
};

export type ScheduledPayoutObject = {
  __typename?: 'ScheduledPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type SearchNonprofitFilterInput = {
  country?: InputMaybe<OrganizationCountry>;
};

export type SearchUnsplashImagesInput = {
  orderBy: UnsplashOrderBy;
  orientation?: InputMaybe<UnsplashOrientation>;
  page: Scalars['Float'];
  query?: InputMaybe<Scalars['String']>;
};

export type SearchUnsplashImagesObject = {
  __typename?: 'SearchUnsplashImagesObject';
  nextPage: Scalars['Float'];
  results: Array<UnsplashImageObject>;
  total: Scalars['Float'];
  total_pages: Scalars['Float'];
};

export type SearchUnsplashImagesResponseObject = {
  __typename?: 'SearchUnsplashImagesResponseObject';
  error?: Maybe<SearchUnsplashImagesResponseObjectErrorObject>;
  object?: Maybe<SearchUnsplashImagesObject>;
};

export type SearchUnsplashImagesResponseObjectErrorObject = {
  __typename?: 'SearchUnsplashImagesResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SendEmailDeliveryError {
  DesignConfigMissing = 'DesignConfigMissing',
  EmailDeliveryNotFound = 'EmailDeliveryNotFound',
  FailedToExportUnlayerHtml = 'FailedToExportUnlayerHtml',
  FailedToGetOwnerEmail = 'FailedToGetOwnerEmail',
  FailedToGetRecipients = 'FailedToGetRecipients',
  FailedToGetReminderRecipients = 'FailedToGetReminderRecipients',
  FailedToGetSmartInviteRecipients = 'FailedToGetSmartInviteRecipients',
  FormNotFound = 'FormNotFound',
  LimitExceeded = 'LimitExceeded',
  ManyRecipientsInPreview = 'ManyRecipientsInPreview',
  SenderNoEmail = 'SenderNoEmail',
  SendingFailed = 'SendingFailed',
  StripeAccountNotActive = 'StripeAccountNotActive',
  UnexpectedError = 'UnexpectedError'
}

export type SendEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'SendEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<SendEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendMergeUsersRequestResponseObject = {
  __typename?: 'SendMergeUsersRequestResponseObject';
  error?: Maybe<SendMergeUsersRequestResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type SendMergeUsersRequestResponseObjectErrorObject = {
  __typename?: 'SendMergeUsersRequestResponseObjectErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendResetPasswordRequestInput = {
  email: Scalars['String'];
  locale: Locales;
};

export type SentryIssueListResponseObject = {
  __typename?: 'SentryIssueListResponseObject';
  error?: Maybe<SentryIssueListResponseObjectErrorObject>;
  items?: Maybe<Array<SentryIssueObject>>;
};

export type SentryIssueListResponseObjectErrorObject = {
  __typename?: 'SentryIssueListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SentryIssueObject = {
  __typename?: 'SentryIssueObject';
  count: Scalars['Float'];
  culprit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userCount: Scalars['Float'];
  value?: Maybe<Scalars['String']>;
};

export type SentryIssueResponseObjectErrorObject = {
  __typename?: 'SentryIssueResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SentrySlot {
  OneHour = 'OneHour',
  SevenDays = 'SevenDays',
  SeventyTwoHours = 'SeventyTwoHours',
  TwentyFourHours = 'TwentyFourHours'
}

export type SessionObject = {
  __typename?: 'SessionObject';
  amount?: Maybe<Scalars['Float']>;
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
};

export type SessionResponseObject = {
  __typename?: 'SessionResponseObject';
  error?: Maybe<SessionResponseObjectErrorObject>;
  object?: Maybe<SessionObject>;
};

export type SessionResponseObjectErrorObject = {
  __typename?: 'SessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SignInAsAdministratorInput = {
  email: Scalars['String'];
  emailTarget: Scalars['String'];
  password: Scalars['String'];
};

export type SignInFrontendInput = {
  email?: InputMaybe<Scalars['String']>;
  googleToken?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  smsCode?: InputMaybe<Scalars['String']>;
  targetOrganizationId?: InputMaybe<Scalars['String']>;
  telemetryId?: InputMaybe<Scalars['String']>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
};

export type SignInInput = {
  email: Scalars['String'];
  encryptPassword?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type SignUpInput = {
  charityNumber?: InputMaybe<Scalars['String']>;
  country: OrganizationCountry;
  email: Scalars['String'];
  firstName: Scalars['String'];
  googleToken?: InputMaybe<Scalars['String']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
  isNonprofitSearchSuccessful?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  locale: Locales;
  npoStarterDealId?: InputMaybe<Scalars['String']>;
  organizationName: Scalars['String'];
  password: Scalars['String'];
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  referredByOrganizationId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<ReferralQuestionChoice>;
  ssoStytchData?: InputMaybe<SsoStytchData>;
  type?: InputMaybe<OrganizationType>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type SsoStytchData = {
  provider: Scalars['String'];
  stytchOrganizationId: Scalars['String'];
  stytchSessionJwt: Scalars['String'];
  stytchUserId: Scalars['String'];
};

export type String4DzmvyLe = {
  __typename?: 'String4DzmvyLe';
  error?: Maybe<String4DzmvyLeErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type String4DzmvyLeErrorObject = {
  __typename?: 'String4DzmvyLeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringC3ycm82r = {
  __typename?: 'StringC3ycm82r';
  error?: Maybe<StringC3ycm82rErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringC3ycm82rErrorObject = {
  __typename?: 'StringC3ycm82rErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringCeNtg3eW = {
  __typename?: 'StringCENtg3eW';
  error?: Maybe<StringCeNtg3eWErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringCeNtg3eWErrorObject = {
  __typename?: 'StringCENtg3eWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringH2lZ99bc = {
  __typename?: 'StringH2lZ99bc';
  error?: Maybe<StringH2lZ99bcErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringH2lZ99bcErrorObject = {
  __typename?: 'StringH2lZ99bcErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringKliV4gpX = {
  __typename?: 'StringKliV4gpX';
  error?: Maybe<StringKliV4gpXErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringKliV4gpXErrorObject = {
  __typename?: 'StringKliV4gpXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringListResponseObject = {
  __typename?: 'StringListResponseObject';
  error?: Maybe<StringListResponseObjectErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringListResponseObjectErrorObject = {
  __typename?: 'StringListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringResponseObject = {
  __typename?: 'StringResponseObject';
  error?: Maybe<StringResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringResponseObjectErrorObject = {
  __typename?: 'StringResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringZBhfDhXk = {
  __typename?: 'StringZBhfDHXk';
  error?: Maybe<StringZBhfDhXkErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringZBhfDhXkErrorObject = {
  __typename?: 'StringZBhfDHXkErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Stringca9Yb62c = {
  __typename?: 'Stringca9YB62c';
  error?: Maybe<Stringca9Yb62cErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type Stringca9Yb62cErrorObject = {
  __typename?: 'Stringca9YB62cErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Stringw60KaPwd = {
  __typename?: 'Stringw60KAPwd';
  error?: Maybe<Stringw60KaPwdErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type Stringw60KaPwdErrorObject = {
  __typename?: 'Stringw60KAPwdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringyH6QHd2v = {
  __typename?: 'StringyH6QHd2v';
  error?: Maybe<StringyH6QHd2vErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringyH6QHd2vErrorObject = {
  __typename?: 'StringyH6QHd2vErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeAccountObject = {
  __typename?: 'StripeAccountObject';
  capabilities: StripeCapabilities;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalAccount?: Maybe<ExternalAccountObject>;
  future_requirements?: Maybe<StripeRequirements>;
  hasAtLeastOnePayout?: Maybe<Scalars['Boolean']>;
  hasOnlyOnePayout?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isBankConnected: Scalars['Boolean'];
  isRejectedByStripe?: Maybe<Scalars['Boolean']>;
  isRejectedByZeffy?: Maybe<Scalars['Boolean']>;
  lastPayout?: Maybe<PayoutObject>;
  payoutInterval: PayoutInterval;
  requirements?: Maybe<StripeRequirements>;
  type?: Maybe<Scalars['String']>;
};

export type StripeAccountResponseObject = {
  __typename?: 'StripeAccountResponseObject';
  error?: Maybe<StripeAccountResponseObjectErrorObject>;
  object?: Maybe<StripeAccountObject>;
};

export type StripeAccountResponseObjectErrorObject = {
  __typename?: 'StripeAccountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  card_issuing?: Maybe<StripeCapabilityStatus>;
  transfers?: Maybe<StripeCapabilityStatus>;
  treasury?: Maybe<StripeCapabilityStatus>;
};

export enum StripeCapabilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type StripeCustomerObject = {
  __typename?: 'StripeCustomerObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  userId: Scalars['String'];
};

export enum StripePayoutStatus {
  canceled = 'canceled',
  failed = 'failed',
  inTransit = 'inTransit',
  paid = 'paid',
  pending = 'pending'
}

export type StripeRequirements = {
  __typename?: 'StripeRequirements';
  current_deadline?: Maybe<Scalars['Float']>;
  currently_due?: Maybe<Array<Scalars['String']>>;
  pending_verification?: Maybe<Array<Scalars['String']>>;
};

export enum SubscriptionRecurrenceInterval {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export enum SubscriptionStatus {
  Cancelled = 'Cancelled',
  InProgress = 'InProgress'
}

export type SwitchOrgAsAdministratorInput = {
  emailTarget: Scalars['String'];
};

export type TagListResponseObject = {
  __typename?: 'TagListResponseObject';
  error?: Maybe<TagListResponseObjectErrorObject>;
  items?: Maybe<Array<TagObject>>;
};

export type TagListResponseObjectErrorObject = {
  __typename?: 'TagListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TagObject = {
  __typename?: 'TagObject';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type TicketListResponseObjectErrorObject = {
  __typename?: 'TicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketObject = {
  __typename?: 'TicketObject';
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  order: OrderObject;
  orderId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  ticketingIndex?: Maybe<Scalars['Float']>;
};

export type TicketResponseObjectErrorObject = {
  __typename?: 'TicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingCategoryObject = {
  __typename?: 'TicketingCategoryObject';
  formCategory: TicketingFormCategory;
  id: Scalars['String'];
};

export type TicketingCategoryObjecti5THpDdP = {
  __typename?: 'TicketingCategoryObjecti5THpDdP';
  error?: Maybe<TicketingCategoryObjecti5THpDdPErrorObject>;
  object?: Maybe<TicketingCategoryObject>;
};

export type TicketingCategoryObjecti5THpDdPErrorObject = {
  __typename?: 'TicketingCategoryObjecti5THpDdPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingDetailsRate = {
  __typename?: 'TicketingDetailsRate';
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  fields: Array<RateFieldObject>;
  id: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
};

export type TicketingDonationFormObject = {
  __typename?: 'TicketingDonationFormObject';
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  campaignId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  registrationIncreaseThermometer: Scalars['Boolean'];
};

export type TicketingFeatureFlagObject = {
  __typename?: 'TicketingFeatureFlagObject';
  isPaymentByPadAllowed: Scalars['Boolean'];
};

export type TicketingFieldObject = {
  __typename?: 'TicketingFieldObject';
  bidLoserEmailContent?: Maybe<Scalars['String']>;
  bidLoserEmailObject?: Maybe<Scalars['String']>;
  chequeDescription?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: Maybe<Scalars['String']>;
  postEventBody?: Maybe<Scalars['String']>;
  postEventSubject?: Maybe<Scalars['String']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  reminderBody?: Maybe<Scalars['String']>;
  reminderSubject?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TicketingFieldObjectgy1E3lFl = {
  __typename?: 'TicketingFieldObjectgy1E3lFl';
  error?: Maybe<TicketingFieldObjectgy1E3lFlErrorObject>;
  object?: Maybe<TicketingFieldObject>;
};

export type TicketingFieldObjectgy1E3lFlErrorObject = {
  __typename?: 'TicketingFieldObjectgy1E3lFlErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingFieldResponseObjectErrorObject = {
  __typename?: 'TicketingFieldResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum TicketingFormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  Shop = 'Shop'
}

export type TicketingListResponseObjectErrorObject = {
  __typename?: 'TicketingListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject = {
  __typename?: 'TicketingObject';
  address?: Maybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerPdfUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  color: Scalars['String'];
  coordinates: CoordinatesObject;
  discounts?: Maybe<Array<DiscountObject>>;
  displayAddressQuestion: Scalars['Boolean'];
  donationForm?: Maybe<TicketingDonationFormObject>;
  eventInvitationEmailDeliveryId?: Maybe<Scalars['String']>;
  eventInvitationEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  eventTimezone?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateExtraDonationReceipt: Scalars['Boolean'];
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  isTicketingFull: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  moneyGathered?: Maybe<Scalars['Float']>;
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  occurrences?: Maybe<Array<TicketingOccurrenceObject>>;
  occurrencesWithRates?: Maybe<Array<OccurrenceWithRatesObject>>;
  orders?: Maybe<Array<OrderObject>>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  postEventEmailDeliveryId?: Maybe<Scalars['String']>;
  postEventEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  postEventSendDateOffset?: Maybe<Scalars['Float']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<QuestionObject>>;
  rates?: Maybe<Array<RateObject>>;
  recurrences?: Maybe<Array<TicketingRecurrenceObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  reminderSendDateOffset?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  soldTicketsCount: Scalars['Float'];
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  thermometerTarget?: Maybe<Scalars['Float']>;
  ticketingFeatureFlagTreatment?: Maybe<TicketingFeatureFlagObject>;
  ticketingFields: Array<TicketingFieldObject>;
  withoutMap: Scalars['Boolean'];
};

export type TicketingObject3Nwnx08I = {
  __typename?: 'TicketingObject3NWNX08I';
  error?: Maybe<TicketingObject3Nwnx08IErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject3Nwnx08IErrorObject = {
  __typename?: 'TicketingObject3NWNX08IErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject4izy9bNk = {
  __typename?: 'TicketingObject4izy9bNK';
  error?: Maybe<TicketingObject4izy9bNkErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject4izy9bNkErrorObject = {
  __typename?: 'TicketingObject4izy9bNKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject4wHVfuGm = {
  __typename?: 'TicketingObject4wHVfuGM';
  error?: Maybe<TicketingObject4wHVfuGmErrorObject>;
  items?: Maybe<Array<TicketingObject>>;
};

export type TicketingObject4wHVfuGmErrorObject = {
  __typename?: 'TicketingObject4wHVfuGMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject5rcbrIgw = {
  __typename?: 'TicketingObject5rcbrIGW';
  error?: Maybe<TicketingObject5rcbrIgwErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject5rcbrIgwErrorObject = {
  __typename?: 'TicketingObject5rcbrIGWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectAkNtLdR7 = {
  __typename?: 'TicketingObjectAkNtLdR7';
  error?: Maybe<TicketingObjectAkNtLdR7ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectAkNtLdR7ErrorObject = {
  __typename?: 'TicketingObjectAkNtLdR7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectRBwXowgY = {
  __typename?: 'TicketingObjectRBwXowgY';
  error?: Maybe<TicketingObjectRBwXowgYErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectRBwXowgYErrorObject = {
  __typename?: 'TicketingObjectRBwXowgYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectb9VaPert = {
  __typename?: 'TicketingObjectb9VaPert';
  error?: Maybe<TicketingObjectb9VaPertErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectb9VaPertErrorObject = {
  __typename?: 'TicketingObjectb9VaPertErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectfN9FeFy5 = {
  __typename?: 'TicketingObjectfN9FeFY5';
  error?: Maybe<TicketingObjectfN9FeFy5ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectfN9FeFy5ErrorObject = {
  __typename?: 'TicketingObjectfN9FeFY5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectfrcB8jxi = {
  __typename?: 'TicketingObjectfrcB8jxi';
  error?: Maybe<TicketingObjectfrcB8jxiErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectfrcB8jxiErrorObject = {
  __typename?: 'TicketingObjectfrcB8jxiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectvYjz3Rof = {
  __typename?: 'TicketingObjectvYjz3ROF';
  error?: Maybe<TicketingObjectvYjz3RofErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectvYjz3RofErrorObject = {
  __typename?: 'TicketingObjectvYjz3ROFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectwvQKg0bl = {
  __typename?: 'TicketingObjectwvQKg0bl';
  error?: Maybe<TicketingObjectwvQKg0blErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectwvQKg0blErrorObject = {
  __typename?: 'TicketingObjectwvQKg0blErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingOccurrenceObject = {
  __typename?: 'TicketingOccurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  closed?: Maybe<Scalars['Boolean']>;
  endUtc: Scalars['Date'];
  eventReminderEmailDelivery?: Maybe<EmailDeliveryObject>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  ticketingId: Scalars['String'];
};

export type TicketingPaymentIntentInput = {
  amount: Scalars['Float'];
  commandId?: InputMaybe<Scalars['String']>;
  connectedAccountId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
  tip: Scalars['Float'];
  ttpoi?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingRecurrenceInput = {
  closeDate?: InputMaybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  repeatUntilDate?: InputMaybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  type: TicketingRecurrenceType;
};

export type TicketingRecurrenceObject = {
  __typename?: 'TicketingRecurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  repeatUntilDate?: Maybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  ticketingId?: Maybe<Scalars['String']>;
  type: TicketingRecurrenceType;
};

export enum TicketingRecurrenceType {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  WeekDays = 'WeekDays',
  Weekly = 'Weekly'
}

export type TicketingResponseObjectErrorObject = {
  __typename?: 'TicketingResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingSummaryObject = {
  __typename?: 'TicketingSummaryObject';
  description: Scalars['String'];
  end?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
};

export enum TipModelExperience {
  Model1 = 'Model1',
  ModelA = 'ModelA',
  ModelB = 'ModelB',
  ModelC = 'ModelC',
  ModelD = 'ModelD',
  ModelE = 'ModelE'
}

export type TipNetVolumeObject = {
  __typename?: 'TipNetVolumeObject';
  volumeFee: Scalars['Float'];
  volumeTip: Scalars['Float'];
};

export type TipNetVolumeResponseObject = {
  __typename?: 'TipNetVolumeResponseObject';
  error?: Maybe<TipNetVolumeResponseObjectErrorObject>;
  object?: Maybe<TipNetVolumeObject>;
};

export type TipNetVolumeResponseObjectErrorObject = {
  __typename?: 'TipNetVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TipSuggestionObject = {
  __typename?: 'TipSuggestionObject';
  amount: Scalars['Int'];
  percentage: Scalars['Int'];
};

export type TipSuggestionResponseListObjectErrorObject = {
  __typename?: 'TipSuggestionResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  stripePayoutId?: InputMaybe<Scalars['String']>;
};

export type TransactionObject = {
  __typename?: 'TransactionObject';
  command?: Maybe<CommandObject>;
  contact?: Maybe<ContactObject>;
  country: OrganizationCountry;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  donation?: Maybe<DonationObject>;
  donationId?: Maybe<Scalars['String']>;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning: Scalars['Boolean'];
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isImported: Scalars['Boolean'];
  isSample?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  nextPaymentAmount?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderObject>;
  orderId?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  paymentMethod: PaymentMethod;
  receipt?: Maybe<ReceiptObject>;
  receiptId?: Maybe<Scalars['String']>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status?: Maybe<PaymentStatus>;
  stripeChargeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  tip: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transferGroup?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Float'];
};

export type TransactionObject4dRXj424 = {
  __typename?: 'TransactionObject4dRXj424';
  error?: Maybe<TransactionObject4dRXj424ErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObject4dRXj424ErrorObject = {
  __typename?: 'TransactionObject4dRXj424ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionObjectM2c3a2Fm = {
  __typename?: 'TransactionObjectM2c3a2Fm';
  error?: Maybe<TransactionObjectM2c3a2FmErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObjectM2c3a2FmErrorObject = {
  __typename?: 'TransactionObjectM2c3a2FmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionRatesWithQuantityObject = {
  __typename?: 'TransactionRatesWithQuantityObject';
  id: Scalars['String'];
  rates?: Maybe<Array<RatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObject29Ut8uS5 = {
  __typename?: 'TransactionRatesWithQuantityObject29Ut8uS5';
  error?: Maybe<TransactionRatesWithQuantityObject29Ut8uS5ErrorObject>;
  items?: Maybe<Array<TransactionRatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObject29Ut8uS5ErrorObject = {
  __typename?: 'TransactionRatesWithQuantityObject29Ut8uS5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionResponseObject = {
  __typename?: 'TransactionResponseObject';
  error?: Maybe<TransactionResponseObjectErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionResponseObjectErrorObject = {
  __typename?: 'TransactionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeListResponseObject = {
  __typename?: 'TransactionsOverTimeListResponseObject';
  error?: Maybe<TransactionsOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<TransactionsOverTimeObject>>;
};

export type TransactionsOverTimeListResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeObject = {
  __typename?: 'TransactionsOverTimeObject';
  created: Scalars['Float'];
  failed: Scalars['Float'];
  processing: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type TransactionsOverTimeResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TranslationsDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  choiceFields?: InputMaybe<Array<ChoiceFieldInput>>;
  description: Scalars['String'];
  donationFormAmountFields: Array<EditDonationFormAmountTranslationInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  message?: InputMaybe<Scalars['String']>;
  questionsFields?: InputMaybe<Array<QuestionFieldInput>>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  transferDescription?: InputMaybe<Scalars['String']>;
};

export type UnsplashImageObject = {
  __typename?: 'UnsplashImageObject';
  alt_description?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  height: Scalars['Float'];
  id: Scalars['String'];
  links: UnsplashLinks;
  urls: UnsplashUrls;
  user: UnsplashUser;
  width: Scalars['Float'];
};

export type UnsplashLinks = {
  __typename?: 'UnsplashLinks';
  download_location: Scalars['String'];
};

export enum UnsplashOrderBy {
  latest = 'latest',
  relevant = 'relevant'
}

export enum UnsplashOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  squarish = 'squarish'
}

export type UnsplashUrls = {
  __typename?: 'UnsplashUrls';
  full: Scalars['String'];
  raw: Scalars['String'];
  regular: Scalars['String'];
  small: Scalars['String'];
  thumb: Scalars['String'];
};

export type UnsplashUser = {
  __typename?: 'UnsplashUser';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UnsubscribePreferenceInput = {
  unsubscribedFromEmailTypes?: InputMaybe<Array<EmailType>>;
};

export type UnsubscribePreferencesObject = {
  __typename?: 'UnsubscribePreferencesObject';
  id: Scalars['String'];
  unsubscribedFromEmailTypes: Array<EmailType>;
};

export type UnsubscribePreferencesObjectpXnxuc5B = {
  __typename?: 'UnsubscribePreferencesObjectpXnxuc5B';
  error?: Maybe<UnsubscribePreferencesObjectpXnxuc5BErrorObject>;
  object?: Maybe<UnsubscribePreferencesObject>;
};

export type UnsubscribePreferencesObjectpXnxuc5BErrorObject = {
  __typename?: 'UnsubscribePreferencesObjectpXnxuc5BErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdateAdministrativeInformationInput = {
  address?: InputMaybe<Scalars['String']>;
  bankDescriptor?: InputMaybe<Scalars['String']>;
  charityNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  hideFromZeffyMap?: InputMaybe<Scalars['Boolean']>;
  isPoBoxAddress: Scalars['Boolean'];
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  propublicaCCode?: InputMaybe<Scalars['Float']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  signatureUrl?: InputMaybe<Scalars['String']>;
  type: OrganizationType;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateAdministrativeMoreInformationInput = {
  category: OrganizationCategory;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
};

export type UpdateDonationFormAmountInput = {
  amount: Scalars['Float'];
  donationFormFieldId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type UpdateOrganizationEngagementFlagsInput = {
  engagementAddContactTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactNotesTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactPaymentsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactTagsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactsBulkTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailRecipientsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailStatsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailTemplatesTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentDetailsTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentsExportTour?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type UpdateOrganizationHubspotPropertyInput = {
  property: Scalars['String'];
  value: Scalars['String'];
};

export type UpdatePaymentIntentInput = {
  billedAmount: Scalars['Int'];
  donationId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  stripePaymentIntentId: Scalars['String'];
  transferAmount: Scalars['Int'];
};

export type UpdatePaymentIntentResponse = {
  __typename?: 'UpdatePaymentIntentResponse';
  error?: Maybe<UpdatePaymentIntentResponseErrorObject>;
  object?: Maybe<UpdatePaymentIntentResponseObject>;
};

export type UpdatePaymentIntentResponseErrorObject = {
  __typename?: 'UpdatePaymentIntentResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdatePaymentIntentResponseObject = {
  __typename?: 'UpdatePaymentIntentResponseObject';
  stripePaymentIntentId: Scalars['String'];
};

export type UpdatePayoutInterval = {
  schedule: PayoutInterval;
};

export type UpdateVolumeThresholdReached = {
  volumeThresholdReached: Scalars['Float'];
};

export type UpsertSessionInput = {
  amount?: InputMaybe<Scalars['Float']>;
  appCodeName: Scalars['String'];
  appName: Scalars['String'];
  appVersion: Scalars['String'];
  defaultSuggestedTip?: InputMaybe<Scalars['Int']>;
  formId?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FormType>;
  id: Scalars['String'];
  isEmbed: Scalars['Boolean'];
  isSuggestedTipsAmount?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  suggestedTips?: InputMaybe<Array<Scalars['Int']>>;
  tipExperimentValue?: InputMaybe<FeatureFlagValue>;
  url: Scalars['String'];
};

export type UserDetailsObject = {
  __typename?: 'UserDetailsObject';
  firstPaymentDate?: Maybe<Scalars['Date']>;
  lastPaymentDate?: Maybe<Scalars['Date']>;
  totalContribution: Scalars['Float'];
};

export type UserListResponseObject = {
  __typename?: 'UserListResponseObject';
  error?: Maybe<UserListResponseObjectErrorObject>;
  items?: Maybe<Array<UserObject>>;
};

export type UserListResponseObjectErrorObject = {
  __typename?: 'UserListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObject = {
  __typename?: 'UserObject';
  contact?: Maybe<ContactObject>;
  contacts?: Maybe<Array<ContactObject>>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  details: UserDetailsObject;
  deviceIds?: Maybe<Array<Scalars['String']>>;
  donations?: Maybe<Array<DonationObject>>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasPassword: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isContactOfAnotherOrganization: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<OrderObject>>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  refreshToken?: Maybe<Scalars['String']>;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  tags?: Maybe<Array<TagObject>>;
};

export type UserObjectc8TlysIn = {
  __typename?: 'UserObjectc8TLYSIn';
  error?: Maybe<UserObjectc8TlysInErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectc8TlysInErrorObject = {
  __typename?: 'UserObjectc8TLYSInErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjecteqXSo0o2 = {
  __typename?: 'UserObjecteqXSo0o2';
  error?: Maybe<UserObjecteqXSo0o2ErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjecteqXSo0o2ErrorObject = {
  __typename?: 'UserObjecteqXSo0o2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectpVfCvkNk = {
  __typename?: 'UserObjectpVfCVKNk';
  error?: Maybe<UserObjectpVfCvkNkErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectpVfCvkNkErrorObject = {
  __typename?: 'UserObjectpVfCVKNkErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectrgAOud2A = {
  __typename?: 'UserObjectrgAOud2A';
  error?: Maybe<UserObjectrgAOud2AErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectrgAOud2AErrorObject = {
  __typename?: 'UserObjectrgAOud2AErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserPermissions {
  Bank = 'Bank',
  Contacts = 'Contacts',
  Donation = 'Donation',
  Receipt = 'Receipt',
  Ticketing = 'Ticketing',
  UserManagement = 'UserManagement'
}

export enum UserRegistrationStatus {
  Registered = 'Registered',
  Unknown = 'Unknown'
}

export type UserResponseObject = {
  __typename?: 'UserResponseObject';
  error?: Maybe<UserResponseObjectErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserResponseObjectErrorObject = {
  __typename?: 'UserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserRoles {
  Donor = 'Donor',
  Member = 'Member',
  Owner = 'Owner'
}

export type UuidInput = {
  id: Scalars['String'];
};

export type VerifyEmailObject = {
  __typename?: 'VerifyEmailObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type VerifyEmailObjectcCEcIpfN = {
  __typename?: 'VerifyEmailObjectcCEcIpfN';
  error?: Maybe<VerifyEmailObjectcCEcIpfNErrorObject>;
  object?: Maybe<VerifyEmailObject>;
};

export type VerifyEmailObjectcCEcIpfNErrorObject = {
  __typename?: 'VerifyEmailObjectcCEcIpfNErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebAnalyticsItemObject = {
  __typename?: 'WebAnalyticsItemObject';
  connectionTime: Scalars['Float'];
  count: Scalars['Float'];
  dnsTime: Scalars['Float'];
  firstContentfulPaint: Scalars['Float'];
  firstPaint: Scalars['Float'];
  loadEventTime: Scalars['Float'];
  pageLoadTime: Scalars['Float'];
  pageRenderTime: Scalars['Float'];
  requestTime: Scalars['Float'];
  responseTime: Scalars['Float'];
  time: Scalars['String'];
};

export type WebAnalyticsObject = {
  __typename?: 'WebAnalyticsObject';
  data?: Maybe<Array<WebAnalyticsItemObject>>;
};

export type WebAnalyticsResponseObject = {
  __typename?: 'WebAnalyticsResponseObject';
  error?: Maybe<WebAnalyticsResponseObjectErrorObject>;
  object?: Maybe<WebAnalyticsObject>;
};

export type WebAnalyticsResponseObjectErrorObject = {
  __typename?: 'WebAnalyticsResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeListResponseObject = {
  __typename?: 'WebhooksOverTimeListResponseObject';
  error?: Maybe<WebhooksOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<WebhooksOverTimeObject>>;
};

export type WebhooksOverTimeListResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeObject = {
  __typename?: 'WebhooksOverTimeObject';
  failed: Scalars['Float'];
  queued: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type WebhooksOverTimeResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ZeffyImportObject = {
  __typename?: 'ZeffyImportObject';
  id: Scalars['String'];
};
